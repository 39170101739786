/* eslint-disable */
import axios from "axios";
import Cookies from "universal-cookie"; // for handling cookies
import { useAuthStore } from "../store/auth"; // Import the Pinia store
import Localbase from "localbase";
const cookies = new Cookies();
const TOKEN_KEY = "authToken"; // Key for storing the token in localStorage
let db = new Localbase("searchDB");
const isDevelopment = process.env.NODE_ENV === "development"; // Check if in development mode

function log(...args) {
  if (isDevelopment) {
    console.log(...args); // Log only in development mode
  }
}

// Set up Axios interceptors to attach tokens to each request
// axios.interceptors.request.use(
//   (config) => {
//     const storedData = JSON.parse(localStorage.getItem('findhub_spa')) || {}
//     const token = storedData.authToken // Get the Bearer token from stored data
//     const csrfToken = cookies.get('XSRF-TOKEN') // Get the CSRF token from cookies

//     if (token) {
//       config.headers['Authorization'] = `Bearer ${token}`
//     }
//     // Only add the x-xsrf-token header if the request is not a preflight request
//     if (csrfToken && config.method !== 'options') {
//       config.headers['x-xsrf-token'] = csrfToken
//     }
//     return config
//   },
//   (error) => {
//     return Promise.reject(error)
//   }
// )

const checkAuthentication = () => {
  const authStore = useAuthStore();
  const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
  const token = storedData.authToken; // Get the Bearer token from stored data

  if (!authStore.isAuthenticated || !token) {
    throw new Error("User is not logged in");
  }
  return { isAuthenticated: authStore.isAuthenticated, user: authStore.user };
  };

export default {
  async autoSuggest(text) {
    return await axios.get(`/api/customer/search/autocomplete`, {
      params: { text },
    });
  },
  async historySearch(query) {
    return await axios.get(`/api/history`, { params: { query } });
  },
  async quickSearch(data) {
    try {
      const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
      const token = storedData.authToken; // Get the token from findhub_spa
      const authStore = useAuthStore();
      const laravelSession = cookies.get("laravel_session"); // Get the laravel_session cookie
      const response = await axios.post(
        "/api/customer/search",
        {
          term: data.term,
          latitude: data.lat,
          longitude: data.lng,
          max_distance: data.max_distance,
        }, // Include fcmToken in the payload
        {
          withCredentials: true, // Make sure this is set to true for CSRF
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 201) {
        if (response.data.user_id === authStore.user.id) {
          this.cacheData("quickSearch", response.data);
          return response.data;
        }
      } else {
        throw new Error("User ID does not match");
      }
    } catch (error) {
      log("Error fetching current user:", error);
      // Log the response data if available
      if (error.response) {
        log("Response data:", error.response.data);
      }
      throw error;
    }
  },
  async searchProducts(id) {
    try {
      const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
      const token = storedData.authToken; // Get the token from findhub_spa
      const laravelSession = cookies.get("laravel_session"); // Get the laravel_session cookie
      const authStore = useAuthStore();
      const response = await axios.get(
        `/api/customer/search/${id}`, // Use template literal to include 'id' in the URL
        {
          withCredentials: true, // Make sure this is set to true for CSRF
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      log("Error fetching current user:", error);
      // Log the response data if available
      if (error.response) {
        log("Response data:", error.response.data);
      }
      throw error;
    }
  },
  async updateSearchTerm(id, data) {
    try {
      const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
      const token = storedData.authToken; // Get the token from findhub_spa
      const response = await axios.put(
        `/api/customer/search/${id}`,
        data, // Use template literal to include 'id' in the URL
        {
          withCredentials: true, // Make sure this is set to true for CSRF
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      log("Error fetching current user:", error);
      // Log the response data if available
      if (error.response) {
        log("Response data:", error.response.data);
      }
      throw error;
    }
  },

  async viewAllSearchTerm() {
    try {
      const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
      const token = storedData.authToken; // Get the token from findhub_spa
      const laravelSession = cookies.get("laravel_session"); // Get the laravel_session cookie
      const authStore = useAuthStore();
      const response = await axios.get(`/api/customer/search/`, {
        withCredentials: true, // Make sure this is set to true for CSRF
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      log("Error fetching current user:", error);
      // Log the response data if available
      if (error.response) {
        log("Response data:", error.response.data);
      }
      throw error;
    }
  },
  async viewSearchTerm(id) {
    try {
      const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
      const token = storedData.authToken; // Get the token from findhub_spa
      const laravelSession = cookies.get("laravel_session"); // Get the laravel_session cookie
      const authStore = useAuthStore();
      const response = await axios.get(`/api/customer/search/${id}`, {
        withCredentials: true, // Make sure this is set to true for CSRF
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      log("Error fetching current user:", error);
      // Log the response data if available
      if (error.response) {
        log("Response data:", error.response.data);
      }
      throw error;
    }
  },
  async cancel(id) {
    try {
      const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
      const token = storedData.authToken; // Get the token from findhub_spa
      const laravelSession = cookies.get("laravel_session"); // Get the laravel_session cookie
      const authStore = useAuthStore();
      const response = await axios.get(`/api/customer/search/${id}/cancel`, {
        withCredentials: true, // Make sure this is set to true for CSRF
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      log("Cancelling current search", error);
      // Log the response data if available
      if (error.response) {
        log("Response data:", error.response.data);
      }
      throw error;
    }
  },
  async getAllCategories() {
    return await axios.get("/api/all_category");
  },
  async searchImage(data) {
    return await axios.post(`/api/search_image`, data);
  },
  async customSearch(payload) {
    try {
      const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
      const token = storedData.authToken; // Get the token from findhub_spa
      const authStore = useAuthStore();
      const response = await axios.post(`/api/customer/search`, payload, {
        withCredentials: true, // Make sure this is set to true for CSRF
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 201) {
        if (response.data.user_id === authStore.user.id) {
          this.cacheData("quickSearch", response.data);
          return response.data;
        }
      }
    } catch (error) {
      log("Error in custom search:", error);
      // Log the response data if available
      if (error.response) {
        log("Response data:", error.response.data);
      }
      throw error;
    }
  },
  async vinLookup(vin) {
    return await axios.get(`/api/vin_lookup`, { params: { vin } });
  },
  async vehicleSearch(vehicleId) {
    return await axios.get(`/api/vehicle_search`, { params: { vehicleId } });
  },
  async cacheData(key, data) {
    const dataToCache = {
      ...data,
      lacal_cached: Date.now(),
    };
    await db.collection(key).add(dataToCache, data.id);
  },
};



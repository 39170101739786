import { defineStore } from "pinia";
import {
  fetchMessagesForSearchTerm,
  fetchNotificationsForUser,
} from "../services/message.service";

export const useMessageStore = defineStore("message", {
  state: () => ({
    allMessages: {}, // Object to store messages for different searchTermIds
    currentMessages: [], // Messages for the current search term
    notifications: [], // Current message object
  }),

  getters: {
    getMessagesBySearchTerm: (state) => (searchTermId) => {
      return state.allMessages[searchTermId] || []; // Return messages or an empty array
    },
    getAllNotifications: (state) => {
      return state.notifications; // Return all notifications
    },
  },

  actions: {
    async loadMessages(searchTermId) {
      if (!this.allMessages[searchTermId]) {
        try {
          const messages = await fetchMessagesForSearchTerm(searchTermId);
          this.allMessages[searchTermId] = messages; // Cache messages
        } catch (error) {
          console.error("Failed to load messages:", error);
        }
      }
      this.currentMessages = this.allMessages[searchTermId] || [];
    },

    setCurrentMessage(newMessage) {
      if (this.currentMessages?.id !== newMessage.message_id) {
        this.currentMessages = newMessage; // Update only if the IDs are different
        console.log("Updated currentMessage:", newMessage);
      } else {
        console.log("Ignored duplicate message:", newMessage);
      }
    },

    async loadNotifications() {
      try {
        const notifications = await fetchNotificationsForUser();
        this.notifications = notifications; // Save notifications
      } catch (error) {
        console.error("Failed to load notifications:", error);
      }
    },
  },
});

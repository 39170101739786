<template>
  <div class="app-layout findhub_spa fh_spa_body">
    <main class="app-main">
      <div class="fh_main_info-bar has-text-centered">
        <strong class="is-primary has-text-danger">
          !Pre-release Testing
          <b-tooltip
            type="is-dark"
            label="Enter the official name of your business"
          >
            <b-icon size="is-small" icon="help-circle-outline"></b-icon>
          </b-tooltip>
        </strong>
      </div>
      <router-view />
      <LoginModalNew
        v-if="modalStore.showLoginModalNew"
        :isVisible="showLoginModalNew"
        @close="closeLoginModal"
      />
    </main>
    <footer class="app-footer">
      <FooterHome />
    </footer>
  </div>
</template>

<script>
/* eslint-disable */
import { useAuthStore, useModalStore } from "./store";
import FooterHome from "./views/layout/FooterHome.vue";
import { messaging } from "@/services/firebaseService"; // Import your Firebase messaging
import LoginModalNew from './components/LoginModalNew.vue';

export default {
  name: "App",
  components: {
    FooterHome,
    LoginModalNew,
  },
  data() {
    return {
      updateData: null,
      showLoginModalNew: false,
      authStore: useAuthStore()
    };
  },
  setup() {
    const modalStore = useModalStore();
    return { modalStore };
  },

  async beforeMount() {
    const authStore = useAuthStore();
    try {
      await authStore.checkAuthStatus();
    }catch (e) {
      console.log("Error on checking")
    }finally {
    }
  },

  mounted() {
    const authStore = useAuthStore();
    // Ensure the user ID is up to date
    const userId = authStore.user?.id || 1; // Use logged-in user ID or default to 1 for testing
    // Log the current authentication state
    messaging.onMessage((payload) => {
      // console.log("Message received in the foreground: ", payload);
      const notificationTitle = payload.notification?.title;
      const notificationBody = payload.notification?.body;
      const image = payload.notification?.image; // Get the image URL from the notification
      // console.log("Notification Body:", notificationBody); // Log the entire body
      // console.log("Notification Image:", image); // Log the entire body
      // Extract the ID from the notification body and log it
      const id = notificationBody; // Assuming the body contains the ID directly
      // console.log("Extracted ID from notification body:", id);
      // Show toast notification with image if available
      if (image) {
        this.$buefy.toast.open({
          message: `${notificationTitle}: ${notificationBody}`,
          type: 'is-info',
          duration: 5000,
          // Use the mixin to display the image
          onClick: () => {
            this.imageUrl(image); // Call the mixin function with the image URL
          }
        });
      } else {
        this.$buefy.toast.open(`${notificationTitle}: ${notificationBody}`);
      }
    });

  },
  methods: {
    closeLoginModal() {
      this.showLoginModalNew = false;
    },
    openLoginModal() {
      console.log("Open Login Modal");
      this.showLoginModalNew = true;
    },
  },
};
</script>

<style lang="scss">
.fh_main_info-bar {
  position: fixed;
  top: 0;
 
  width: 100vw;
  height: 1.6rem;
}

.findhub_spa {
  height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;

  justify-content: space-between;
}

.app-main {
  flex-grow:1
}

.findhub_spa-bg {
  // background-image: url("../public/img/beautiful-abstract.jpg");
  //   /* Add your image path here */
  // background-size: cover;
  //  /* Ensure the image covers the entire area */
  // background-position: center;
  // position: absolute;
  // width: 100vw;
  // height: 100vh;
  // top: 0;
  // left: 0;
  // filter: blur(3px);
}

.app-footer {}

@media (max-width: 600px) {
  // Changed from 550.56px to 600px

  /* Adjust the max-width as needed */
  .app-main {
    padding: 0rem !important;
    /* Reduced padding for mobile */
  }

  .container is-fluid {

    padding: .5rem;
  }
}

@media (max-width: 1024px) {
  // Changed from 550.56px to 600px

  /* Adjust the max-width as needed */
  .app-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /* Reduced padding for mobile */
  }

  .container is-fluid {}
}
</style>

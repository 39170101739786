// router/index.js
import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore, useModalStore } from "../store"; // Import auth store for checking authentication

import HomeView from "../views/HomeView.vue";
import AboutUs from "../views/pages/AboutUs.vue";
import PrivacyPolicy from "../views/pages/PrivacyPolicy.vue";
import ContactUs from "../views/pages/ContactUs.vue";
import SearchResult from "@/views/SearchResult.vue";
import SupportChat from "@/views/SupportChat.vue";
import SellerDirectories from "@/views/pages/SellerDirectories.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutUs,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactUs,
  },
  {
    path: "/search/:searchterm_id",
    name: "searchresult",
    component: SearchResult,
    props: true,
    meta: { requiresAuth: true }, // Optional if you want to use this in future for restricting routes
  },
  {
    path: "/support-chat",
    name: "support-chat",
    component: SupportChat,
    meta: { requiresAuth: true }, // Optional if you want to use this in future for restricting routes
  },
  {
    path: "/join-as-seller",
    name: "join-as-seller",
    component: () => import("@/views/JoinAsSeller.vue"),
    meta: { requiresAuth: false }, // Optional if you want to use this in future for restricting routes
  },
  {
    path: "/:id/profile",
    name: "user-profile",
    component: () => import("@/views/pages/Profile.vue"),
    meta: { requiresAuth: true }, // Assuming only logged-in users can access their profile
  },
  {
    path: "/seller-directories",
    name: "seller-directories",
    component: SellerDirectories,
    meta: { requiresAuth: false }, // Assuming only logged-in users can access their profile
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () => import("@/views/pages/CheckoutPage.vue"),
    meta: { requiresAuth: true }, // Assuming only logged-in users can access their profile
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("@/views/pages/Orders.vue"),
    meta: { requiresAuth: true }, // Assuming only logged-in users can access their profile
  },
  {
    path: "/oauth-redirect",
    name: "oauth-redirect",
    component: () => import("@/views/OAuthRedirect.vue"),
    meta: { requiresAuth: false }, // Adjust as necessary for your authentication flow
  },
  {
    path: "/:catchAll(.*)", // Catch all unknown routes
    name: "not-found",
    component: () => import("@/views/pages/NotFound.vue"), // Import your NotFound component
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Global navigation guard to check authentication status without blocking navigation
router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const modalStore = useModalStore();

  // Call the checkAuthStatus function to update Pinia auth store
  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    console.log("Not authenticated");
    // Emit event or directly manipulate the modal state
    modalStore.openLoginModal(to.fullPath); // Pass the full path of the intended route
    next(false); // Prevent navigation
  } else {
    next(); // Proceed to route
  }
});

export default router;

import axios from "axios";
import Cookies from "universal-cookie"; // for handling cookies
const cookies = new Cookies();

export class ContactUsService {
  static async contactFormData(reqData) {
    const response = await axios.post(`/api/customer/contact_form`, reqData, {
      headers: {
        "Content-Type": "application/json",
        "x-xsrf-token": cookies.get("XSRF-TOKEN"), // Pass the CSRF token
      },
      withCredentials: true, // Enable withCredentials
    });
    return response;
  }
}

<template>
  <Nav />
  <div class="fh_contact_us_page-image"></div>
  <div class="fh_contact-us_container">
    <div class="fh_contact-us_container-address">
      <div class="fh_contact-us_container-address_box">
        <article class="media">
          <figure class="media-left">
            <p class="image is-32x32">
              <img src="/icons/PLACEHOLDER.png" />
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>
                <strong>FindHub IT Solutions FZC</strong>
                <br />
                Business Centre,<br />Sharjah Publishing City Free Zone,
                <br />Sharjah, <br />United Arab Emirates
              </p>
            </div>
          </div>
        </article>
        <article class="media">
          <figure class="media-left">
            <p class="image is-32x32">
              <img src="/icons/NEW MESSAGE.png" />
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>info@findhub.ae</p>
            </div>
          </div>
        </article>
        <article class="media">
          <figure class="media-left">
            <p class="image is-32x32">
              <img src="/icons/CALLS.png" />
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>+971 55 3740714</p>
            </div>
          </div>
        </article>
      </div>
    </div>
    <div class="fh_contact-us_container-form">
      <h1 class="title is-size-3">Contact Us</h1>
      <b-field label="Name" v-model="name">
        <b-input placeholder="Name" v-model="name"></b-input>
      </b-field>

      <b-field label="Email">
        <b-input
          type="email"
          maxlength="30"
          placeholder="Email"
          v-model="email"
        >
        </b-input>
      </b-field>

      <b-field label="Subject">
        <b-select placeholder="Select a subject">
          <option
            v-for="option in referenceCodes.data"
            :key="option.name"
            :value="option.code"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Message" :label-position="labelPosition">
        <b-input maxlength="200" type="textarea" v-model="message"></b-input>
      </b-field>
      <b-field grouped position="is-right">
        <p class="control">
          <b-button label="Submit" type="is-primary" v-on:click="onSubmit()" />
        </p>
      </b-field>
    </div>
  </div>
</template>

<script>
// import { mapActions } from "vuex";
import { ContactUsService } from "@/services/contact_us.service";
import { useRefCodeStore } from "@/store";
import Nav from "@/components/Nav.vue";

export default {
  components: {
    Nav,
  },
  name: "ContactUs",
  data: function () {
    return {
      contact_form_issuetype: [],
      errors: [],
      name: null,
      email: null,
      data: null,
      issue_type: null,
      message: null,
      contact_us_form: {
        loader: false,
      },
      refCodeStore: null,
      referenceCodes: [], // Initialize referenceCodes as an empty array
    };
  },
  created() {
    this.initializeRefCodeStore();
  },
  methods: {
    async initializeRefCodeStore() {
      this.refCodeStore = useRefCodeStore();
      await this.refCodeStore.refreshReferenceCodes(["contact_form_issuetype"]);
      this.referenceCodes =
        this.refCodeStore.referenceCodes.contact_form_issuetype; // Store reference codes in a variable
    },
    async onSubmit() {
      const reqData = {
        name: this.name,
        email: this.email,
        issue_type: this.issue_type,
        message: this.message,
      };
      try {
        this.response = await ContactUsService.contactFormData(reqData);
        console.log(this.response);
        this.$buefy.notification.open({
          message: "Thank you for your message. It has been sent.",
          type: "is-success",
        });
        this.contact_us_form.loader = false;
        this.clearInputFields();
      } catch (e) {
        this.contact_us_form.loader = false;
      }
    },
    clearInputFields(e) {
      this.name = "";
      this.email = "";
      this.issue_type = "";
      this.message = "";
      e.preventDefault();
    },
  },
};
</script>

<style scoped>
.fh_contact_us_page-image {
  background-image: url("../../../public/img/background_1.jpg");
  position: relative;
  height: 90%;
}

.fh_contact-us_container {
  width: 60%;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem 5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.fh_contact-us_container-address {
  width: 50%;
  padding: 5rem;
}

.fh_contact-us_container-address_box {
}

.fh_contact-us_container-form {
  flex-grow: 1;
  background-color: white;
  padding: 1rem 2rem;
}
.fh_contact-us_container-form_box {
}

.fh_contact-us {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.fh_aboutus-page_banner {
  background-image: url("../../../public/img/aboutus_banner_1.jpg");
  /* Update with your image path */
  background-size: cover;
  /* Ensures the image covers the entire section */
  background-position: center;
  /* Centers the image */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Light drop shadow */
  width: 100%;
}
</style>

import axios from "axios";
import Cookies from "universal-cookie";

// Retrieve the token from localStorage
const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
const token = storedData.authToken; // Get the auth token from findhub_spa
const cookies = new Cookies(); // Create an instance of universal-cookie

export default {
  async placeOrder(orderData) {
    const response = await axios.post("/api/customer/order", orderData, {
      withCredentials: true, // Make sure this is set to true for CSRF
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },
  async updateShippingAddress(addressData) {
    const response = await axios.post(
      "/api/customer/order/update_shipping_address",
      addressData,
      {
        withCredentials: true, // Make sure this is set to true for CSRF
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  },
  async updatePaymentMode(paymentMode) {
    const response = await axios.post(
      "/api/customer/order/update_payment_and_place_order",
      paymentMode,
      {
        withCredentials: true, // Make sure this is set to true for CSRF
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  },
  async confirmPayment(paymentMode) {
    const response = await axios.post(
      "/api/customer/order/confirm_payment",
      paymentMode,
      {
        withCredentials: true, // Make sure this is set to true for CSRF
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  },

  async getAllMyOrders() {
    const response = await axios.get("/api/customer/order", {
      withCredentials: true, // Make sure this is set to true for CSRF
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  },
  async getOrders(data) {
    const response = await axios.get(`/api/customer/order/${data.id}`, data, {
      withCredentials: true, // Make sure this is set to true for CSRF
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
  },
};

import { defineStore } from "pinia";

export const useModalStore = defineStore("modal", {
  state: () => ({
    showLoginModalNew: false,
    intendedRoute: null, // New property to store the route
  }),
  actions: {
    openLoginModal(route) {
      this.showLoginModalNew = true;
      this.intendedRoute = route || null; // Store the route if provided
    },
    closeLoginModal() {
      this.showLoginModalNew = false;
      this.intendedRoute = null; // Clear the route after modal is closed
    },
  },
});

/* eslint-disable */
import firebase from "firebase/compat/app"; // Use compat for older versions
import "firebase/compat/messaging"; // Use compat for messaging
import { Notification } from "@capacitor/core";

// Use environment variables for different configurations
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};
firebase.initializeApp(firebaseConfig);

// Initialize Firebase Messaging
const messaging = firebase.messaging();

// Function to request notification permission
export const requestNotificationPermission = async () => {
  if (process.env.NODE_ENV === "development") {
    console.log("Notification permission requests are disabled in development.");
    return true;
  }

  if (!("Notification" in window)) {
    console.error("This browser does not support notifications.");
    return false;
  }

  if (typeof Notification.requestPermission !== "function") {
    console.error("Notification.requestPermission is not a function.");
    return false;
  }

  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
      return true;
    } else {
      console.error("Notification permission denied.");
      return false;
    }
  } catch (error) {
    console.error("Error requesting notification permission:", error);
    return false;
  }
};

// Request FCM token (called when you need to get the FCM token)
export const requestFCMToken = async () => {
  const permissionGranted = await requestNotificationPermission();
  if (!permissionGranted) {
    return; // Exit if permission is not granted
  }

  try {
    const token = await messaging.getToken({
      vapidKey: process.env.VUE_APP_VAPID_KEY,
    });

    if (token) {
      // console.log("FCM Token:", token);
      // Save token to backend or local storage
      await saveTokenToBackend(token);
      return token;
    } else {
      console.error(
        "No FCM token available. Make sure permissions are granted."
      );
    }
  } catch (error) {
    console.error("Error getting FCM token:", error);
  }
};

// Function to save FCM token to your backend (customize this)
const saveTokenToBackend = async (token) => {
  // Implement your API call to save the token
  // For example: await api.post('/save-token', { token });
};

// Handle foreground messages (use this in Vue components)
export const handleForegroundMessages = (callback) => {
  messaging.onMessage((payload) => {
    console.log("Message received in foreground: ", payload);
    if (callback) {
      callback(payload);
    }
  });
};

export { messaging };

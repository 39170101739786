<template>
  <footer class="footer fh_app_main_footer">
    <div class="columns">
      <!-- <div class="column">
        <article class="media pt-5">
          <figure class="media-left">
            <p class="image findhub_footer_brand-logo">
              <img src="/logos/Logo_Only@2x.png" />
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                ornare magna eros, eu pellentesque tortor vestibulum ut.
                Maecenas non massa sem. Etiam finibus odio quis feugiat
                facilisis.
              </p>
            </div>
          </div>
        </article>
      </div> -->
      <!-- <div class="column has-text-left has-text-centered-mobile pt-5">
        <div class="content is-small">
          <img
            src="/logos/logo_name_slogan_shape@2x.png"
            alt="findhub_logo"
            class="findhub_footer_brand-logo"
          />
          <p>
            Founded by two colleagues in 2020, FindHub is just as the same
            suggests. An online UAE-based local search engine that uses the best
            of technology to bring you what you have been searching for. Minimum
            labour, easy use, and great accessibility are just some of the many
            features of our application. It doesn't get easier than this!
          </p>
          <div class="is-flex is-justify-content-center">
            <img
              class="image findhub_footer_store-icon"
              src="/img/icons/apple_store.png"
            />

            <img
              class="findhub_footer_store-icon"
              src="/img/icons/play_store.png"
            />
          </div>
        </div>
      </div> -->
      <!-- <div class="column is-hidden-mobile">
        <div class="block">
          <div class="content ml-6">
            <ul>
              <li v-for="link in links" :key="link.to">
                <router-link :to="link.to">{{ link.text }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div> -->
      <!-- <div class="column pt-6 is-hidden-mobile">
        <div class="content has-text-justified-mobile">
          <p class="pb-0 mb-0"><strong>Findhub IT Solutions FZC</strong></p>
          <p>
            Business Cemter <br />Sharjah Publishing City Freezone <br />
            Sharjah <br />
            United Arab Emirates <br />
            +971 55 3740714
          </p>
        </div>
      </div> -->
    </div>
    <div class="columns">
      <div class="column is-full has-text-centered p-0">
        <div class="content fh_footer_bottom_container">
          <p class="mb-0">
            Copyrate 2024 FindHub IT Solutions FZC. All rights reserved.
          </p>
          <div class="social-media-icons">
            <a href="#" target="_blank" rel="noopener noreferrer">
              <span class="mdi mdi-24px mdi-facebook"></span>
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <span class="mdi mdi-24px mdi-twitter"></span>
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <span class="mdi mdi-24px mdi-instagram"></span>
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <span class="mdi mdi-24px mdi-linkedin"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterHome",
  data() {
    return {
      links: [
        { to: "/what-we-do", text: "What We Do" },
        { to: "/privacy-policy", text: "Privacy Policy" },
        { to: "/terms-of-service", text: "Terms of Service" },
        { to: "/refund-policy", text: "Refund Policy" },
        { to: "/faq", text: "FAQ" },
        { to: "/join-as-seller", text: "Join as a Seller" },
        { to: "/blog", text: "Blog" },
      ],
    };
  },
};
</script>

<style scoped>
.findhub_footer_store-icon {
  max-width: 8rem;
  margin-right: 1rem;
}

.findhub_footer_brand-logo {
  max-width: 5rem;
  filter: brightness(0);
}

.fh_app_main_footer {
  padding: 1rem 1rem;
  background-color: hsl(0, 0%, 98%);
}

.fh_footer_bottom_container {
  display: flex;
  justify-content: space-between;
}

.social-media-icons a {
  color: #007f6d;
}
@media (max-width: 640px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .fh_app_main_footer {
    padding: 0.4rem 0rem;
    /* Padding for small screens */
    font-size: 0.8rem;
  }
  .fh_footer_bottom_container {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 1024px) {
  /* Tablet breakpoint adjustments */
  .fh_app_main_footer {
    padding: 0.6rem 0rem;
    /* Adjust padding for tablets */
    font-size: 0.9rem;
    /* Slightly larger font size for tablets */
  }

  .fh_footer_bottom_container {
    display: flex;
    flex-direction: row;
    /* Change back to row for tablets */
  }
}

/* Add any additional Tailwind classes if needed */
</style>

<template>
  <Nav />
  <div class="container">
    <section class="hero is-primary">
      <div class="hero-body">
        <p class="title">Privacy Policy</p>
        <b-breadcrumb align="is-left">
          <b-breadcrumb-item href="/">Home</b-breadcrumb-item>
          <b-breadcrumb-item href="/documentation/breadcrumb" active>
            Privacy Policy
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </section>
    <div class="content">
      <div class="block">
        <div class="content p-5 has-text-justified">
          <p class="mt-5">
            Your privacy is important to us. It is FindHub's policy to respect
            your privacy and comply with any applicable law and regulation
            regarding any personal information we may collect about you,
            including across our website,
            <a href="https://findhub.app">https://findhub.app/</a>, and other
            sites we own and operate.
          </p>
          <p>
            Personal information is any information about you which can be used
            to identify you. This includes information about you as a person
            (such as name, address, and date of birth), your devices, payment
            details, and even information about how you use a website or online
            service.
          </p>
        </div>
        <div class="content p-5 has-text-justified">
          <p>
            In the event our site contains links to third-party sites and
            services, please be aware that those sites and services have their
            own privacy policies. After following a link to any third-party
            content, you should read their posted privacy policy information
            about how they collect and use personal information. This Privacy
            Policy does not apply to any of your activities after you leave our
            site.
          </p>
          <p>This policy is effective as of 5 May 2021.</p>
          <p>Last updated: 5 May 2021</p>
        </div>
        <div class="content p-5 has-text-justified">
          <h5 class="title is-5">Information We Collect</h5>
          <p>
            Information we collect falls into one of two categories:
            'voluntarily provided' information and 'automatically collected'
            information.
          </p>
          <p>
            'Voluntarily provided' information refers to any information you
            knowingly and actively provide us when using or participating in any
            of our services and promotions.
          </p>
          <p>
            'Automatically collected' information refers to any information
            automatically sent by your devices in the course of accessing our
            products and services.
          </p>
        </div>
        <div class="content p-5 has-text-justified">
          <h5 class="title is-5">Log Data</h5>
          <p>
            When you visit our website, our servers may automatically log the
            standard data provided by your web browser. It may include your
            device’s Internet Protocol (IP) address, your browser type and
            version, the pages you visit, the time and date of your visit, the
            time spent on each page, and other details about your visit.
          </p>
          <p>
            Additionally, if you encounter certain errors while using the site,
            we may automatically collect data about the error and the
            circumstances surrounding its occurrence. This data may include
            technical details about your device, what you were trying to do when
            the error happened, and other technical information relating to the
            problem. You may or may not receive notice of such errors, even in
            the moment they occur, or what the nature of the error is.
          </p>
          <p>
            Please be aware that while this information may not be personally
            identifying by itself, it may be possible to combine it with other
            data to personally identify individual persons.
          </p>
        </div>
        <div class="content p-5 has-text-justified">
          <h5 class="title is-5">Device Data</h5>
          <p>
            When you visit our website or interact with our services, we may
            automatically collect data about your device, such as:
          </p>
          <ul>
            <li>Device Type</li>
            <li>Operating System</li>
            <li>Unique device identifiers</li>
            <li>Device settings</li>
            <li>Geo-location data</li>
          </ul>
          <p>
            Data we collect can depend on the individual settings of your device
            and software. We recommend checking the policies of your device
            manufacturer or software provider to learn what information they
            make and other technical information relating to the problem. You
            may or may not receive notice of such errors, even in the moment
            they occur, that they have occurred, or what the nature of the error
            is.
          </p>
          <p>
            Please be aware that while this information may not be personally
            identifying by itself, it may be possible to combine it with other
            data to personally identify individual persons.
          </p>
        </div>
        <div class="content p-5 has-text-justified">
          <h5 class="title is-5">Personal Information</h5>
          <p>
            We may ask for personal information — for example, when you submit
            content to us or when you contact us — which may include one or more
            of the following:
          </p>
          <ul>
            <li>Name</li>
            <li>Email</li>
            <li>Social media profiles</li>
            <li>Date of birth</li>
            <li>Phone/mobile number</li>
            <li>Home/mailing address</li>
            <li>Business Information</li>
            <li>Business License</li>
            <li>Emirates ID</li>
            <li>VAT/Tax Certificate</li>
            <li>Logo</li>
          </ul>
        </div>
        <div class="content p-5 has-text-justified">
          <h5 class="title is-5">User-Generated Content</h5>
          <p>
            We consider 'user-generated content' to be materials (text, image
            and/or video content) voluntarily supplied to us by our users for
            the purpose of publication on our website or re-publishing on our
            social media channels. All user-generated content is associated with
            the account or email address used to submit the materials.
          </p>
          <p>
            Please be aware that any content you submit for the purpose of
            publication will be public after posting (and subsequent review or
            vetting process). Once published it may be accessible to third
            parties not covered under this privacy policy.
          </p>
        </div>
        <div class="content p-5 has-text-justified">
          <h5 class="title is-5">
            Legitimate Reasons for Processing Your Personal Information
          </h5>
          <p>
            We only collect and use your personal information when we have a
            legitimate reason for doing so. In which instance we only collect
            personal information that is reasonably necessary to provide our
            services to you.
          </p>
        </div>
        <div class="content p-5 has-text-justified">
          <h5 class="title is-5">Collection and Use of Information</h5>
          <p>
            We may collect personal information from you when you do any of the
            following on our website:
          </p>
          <ul>
            <li>Register for an account</li>
            <li>
              Sign up to receive updates from us via email or social media
              channels
            </li>
            <li>Use a mobile device or web browser to access our content</li>
            <li>
              Contact us via email, social media, or on any similar technologies
            </li>
            <li>When you mention us on social media</li>
          </ul>
          <p>
            We may collect, hold, use and disclose information for the following
            purposes, and personal information will not be further processed in
            a manner that is incompatible with these purposes:
          </p>
          <ul>
            <li>
              To provide you with our platform's core features and services
            </li>
            <li>
              To enable you to customize or personalise your experience of our
              website
            </li>
            <li>To deliver products and/or services to you</li>
            <li>To contact and communicate with you</li>
            <li>
              For analytics, market research, and business development,
              including to operate and improve our website, associated
              applications, and associated social media platforms
            </li>
            <li>
              For advertising and marketing, including to send you promotional
              information about our products and services and information about
              third parties that we consider may be of interest to you
            </li>
            <li>
              For technical assessment, including to operate and improve our
              app, associated applications, and associated social media
              platforms
            </li>
          </ul>
          <p>
            We may combine voluntarily provided and automatically collected
            personal information with general information or research data we
            receive from other trusted sources. For example, Our marketing and
            market research activities may uncover data and insights, which we
            may combine with information about how visitors use our site to
            improve our site and your experience on it.
          </p>
        </div>
        <div class="content p-5 has-text-justified">
          <h5 class="title is-5">Security of Your Personal Information</h5>
          <p>
            When we collect and process personal information, and while we
            retain this information, we will protect it within commercially
            acceptable means to prevent loss and theft, as well as unauthorised
            access, disclosure, copying, use or modification.
          </p>
          <p>
            Although we will do our best to protect the personal information you
            provide to us, we advise that no method of electronic transmission
            or storage is 100% secure and no one can guarantee absolute data
            security.
          </p>
          <p>
            You are responsible for selecting any password and its overall
            security strength, ensuring the security of your own information
            within the bounds of our services. For example, ensuring any
            passwords associated with accessing your personal information and
            accounts are secure and confidential.
          </p>
        </div>
        <div class="content p-5 has-text-justified">
          <h5 class="title is-5">How Long We Keep Your Personal Information</h5>
          <p>
            We keep your personal information only for as long as we need to.
            This time period may depend on what we are using your information
            for, in accordance with this privacy policy. For example, if you
            have provided us with personal information as part of creating an
            account with us, we may retain this information for the duration
            your account exists on our system. If your personal information is
            no longer required for this purpose, we will delete it or make it
            anonymous by removing all details that identify you.
          </p>
          <p>
            However, if necessary, we may retain your personal information for
            our compliance with a legal, accounting, or reporting obligation or
            for archiving purposes in the public interest, scientific, or
            historical research purposes or statistical purposes.
          </p>
        </div>
        <div class="content p-5 has-text-justified">
          <h5 class="title is-5">
            Disclosure of Personal Information to Third Parties
          </h5>
          <p>We may disclose personal information to:</p>
          <ul>
            <li>A parent, subsidiary or affiliate of our company</li>
            <li>
              Third-party service providers for the purpose of enabling them to
              provide their services including (without limitation) IT service
              providers, data storage, hosting and server providers, ad
              networks, error loggers, debt collectors, maintenance or
              problem-solving providers, marketing or advertising providers,
              professional advisors, and payment systems operators
            </li>
            <li>Our employees, contractors, and/or related entities</li>
            <li>Our existing or potential agents or business partners</li>
            <li>
              Credit reporting agencies, courts, tribunals, and regulatory
              authorities, in the event you fail to pay for goods or services we
              have provided to you
            </li>
            <li>
              Courts, tribunals, regulatory authorities, and law enforcement
              officers, as required by law, in connection with any actual or
              prospective legal proceedings, or in order to establish, exercise,
              or defend our legal rights
            </li>
            <li>
              Third parties, including agents or sub-contractors who assist us
              in providing information, products, services, or direct marketing
              to you
            </li>
            <li>Third parties to collect and process data</li>
            <li>
              An entity that buys, or to which we transfer all or substantially
              all of our assets and business
            </li>
          </ul>
          <p>Third parties we currently use include:</p>
          <ul>
            <li>MailChimp</li>
            <li>Google Adsense</li>
          </ul>
        </div>
        <div class="content p-5 has-text-justified">
          <h5 class="title is-5">
            International Transfers of Personal Information
          </h5>
          <p>
            The personal information we collect is stored and/or processed in
            India, and United Arab Emirates, or where we or our partners,
            affiliates, and third-party providers maintain facilities.
          </p>
          <p>
            The countries to which we store, process, or transfer your personal
            information may not have the same data protection laws as the
            country in which you initially provided the information. If we
            transfer your personal information to third parties in other
            countries: (i) we will perform those transfers in accordance with
            the requirements of applicable law; and (ii) we will protect the
            transferred personal information in accordance with this privacy
            policy.
          </p>
        </div>
        <div class="content p-5 has-text-justified">
          <h5 class="title is-5">
            Your Rights and Controlling Your Personal Information
          </h5>
          <p>
            <strong>Your choice:</strong> By providing personal information to
            us, you understand we will collect, hold, use, and disclose your
            personal information in accordance with this privacy policy. You do
            not have to provide personal information to us, however, if you do
            not, it may affect your use of our website or the products and/or
            services offered on or through it.
          </p>
          <p>
            <strong>Information from third parties:</strong> If we receive
            personal information about you from a third party, we will protect
            it as set out in this privacy policy. If you are a third party
            providing personal information about somebody else, you represent
            and warrant that you have such person’s consent to provide the
            personal information to us.
          </p>
          <p>
            <strong>Marketing permission:</strong> If you have previously agreed
            to us using your personal information for direct marketing purposes,
            you may change your mind at any time by contacting us using the
            details below.
          </p>
          <p>
            <strong>Access:</strong> You may request details of the personal
            information that we hold about you.
          </p>
          <p>
            <strong>Correction:</strong> If you believe that any information we
            hold about you is inaccurate, out of date, incomplete, irrelevant,
            or misleading, please contact us using the details provided in this
            privacy policy. We will take reasonable steps to correct any
            information found to be inaccurate, incomplete, misleading, or out
            of date.
          </p>
          <p>
            <strong>Non-discrimination:</strong> We will not discriminate
            against you for exercising any of your rights over your personal
            information. Unless your personal information is required to provide
            you with a particular service or offer (for example providing user
            support), we will not deny you goods or services and/or charge you
            different prices or rates for goods or services, including through
            granting discounts or other benefits, or imposing penalties, or
            provide you with a different level or quality of goods or services.
          </p>
          <p>
            <strong>Downloading of Personal Information:</strong> We provide a
            means for you to download the personal information you have shared
            through our site. Please contact us for more information.
          </p>
          <p>
            <strong>Notification of data breaches:</strong> We will comply with
            laws applicable to us in respect of any data breach.
          </p>
          <p>
            <strong>Complaints:</strong> If you believe that we have breached a
            relevant data protection law and wish to make a complaint, please
            contact us using the details below and provide us with full details
            of the alleged breach. We will promptly investigate your complaint
            and respond to you, in writing, setting out the outcome of our
            investigation and the steps we will take to deal with your
            complaint. You also have the right to contact a regulatory body or
            data protection authority in relation to your complaint.
          </p>
          <p>
            <strong>Unsubscribe:</strong> To unsubscribe from our email database
            or opt-out of communications (including marketing communications),
            please contact us using the details provided in this privacy policy,
            or opt-out using the opt-out facilities provided in the
            communication. We may need to request specific information from you
            to help us confirm your identity.
          </p>
        </div>
        <div class="content p-5 has-text-justified">
          <h5 class="title is-5">Use of Cookies</h5>
          <p>
            We use 'cookies' to collect information about you and your activity
            across our site. A cookie is a small piece of data that our website
            stores on your computer, and accesses each time you visit, so we can
            understand how you use our site. This helps us serve you content
            based on preferences you have specified.
          </p>
          <p>Please refer to our Cookie Policy for more information.</p>
        </div>
        <div class="content p-5 has-text-justified">
          <h5 class="title is-5">Business Transfers</h5>
          <p>
            If we or our assets are acquired, or in the unlikely event that we
            go out of business or enter bankruptcy, we would include data,
            including your personal information, among the assets transferred to
            any parties who acquire us. You acknowledge that such transfers may
            occur, and that any parties who acquire us may, to the extent
            permitted by applicable law, continue to use your personal
            information according to this policy, which they will be required to
            assume as it is the basis for any ownership or use rights we have
            over such information.
          </p>
        </div>
        <div class="content p-5 has-text-justified">
          <h5 class="title is-5">Limits of Our Policy</h5>
          <p>
            Our website may link to external sites that are not operated by us.
            Please be aware that we have no control over the content and
            policies of those sites, and cannot accept responsibility or
            liability for their respective privacy practices.
          </p>
        </div>
        <div class="content p-5 has-text-justified">
          <h5 class="title is-5">Changes to This Policy</h5>
          <p>
            At our discretion, we may change our privacy policy to reflect
            updates to our business processes, current acceptable practices, or
            legislative or regulatory changes. If we decide to change this
            privacy policy, we will post the changes here at the same link by
            which you are accessing this privacy policy.
          </p>
          <p>
            If the changes are significant, or if required by applicable law, we
            will contact you (based on your selected preferences for
            communications from us) and all our registered users with the new
            details and links to the updated or changed policy.
          </p>
          <p>
            If required by law, we will get your permission or give you the
            opportunity to opt in to or opt out of, as applicable, any new uses
            of your personal information.
          </p>
        </div>
        <div class="content p-5 has-text-justified">
          <h5 class="title is-5">Contact Us</h5>
          <p>
            For any questions or concerns regarding your privacy, you may
            contact us using the following details:
          </p>
          <p>FindHub Info<br />info@findhub.ae</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
export default {
  components: {
    Nav,
  },
  name: "PrivacyPolicy",
};
</script>

<style scoped>
.container {
  max-height: 88vh; /* Set a maximum height for the container */
  overflow-y: auto; /* Allow vertical scrolling */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Edge */
}
</style>

<template>
  <b-modal
    v-model="isComponentModalActive"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-label="Example Modal"
    close-button-aria-label="Close"
    aria-modal
  >
    <template #default="props">
      <login-modal v-bind="formProps" @close="props.close"></login-modal>
    </template>
  </b-modal>
</template>

<script>
import LoginModal from "./LoginModal.vue";

export default {
  name: "LoginModalNew",
  components: {
    LoginModal,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  data() {
    return {
      isComponentModalActive: true,
      formProps: {
        email: "evan@you.com",
        password: "testing",
      },
    };
  },
  methods: {
    login() {
      // Call your authentication service or API
      console.log("Logging in with:", this.form);
      // After successful login, close the modal
      this.$emit("close");
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

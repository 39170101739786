import axios from "axios";
import Cookies from "universal-cookie";

// Retrieve the token from localStorage
const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
const token = storedData.authToken; // Get the auth token from findhub_spa
const cookies = new Cookies(); // Create an instance of universal-cookie

export default {
  async addToCart(data) {
    const response = await axios.post(`/api/customer/cart/items`, data, {
      withCredentials: true, // Make sure this is set to true for CSRF
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },
  async removeFromCart(data) {
    const response = await axios.post(`/api/customer/cart/items/delete`, data, {
      withCredentials: true, // Make sure this is set to true for CSRF
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },
  async clearCart() {
    await axios.post("/api/cart/clear");
  },
  async getCart() {
    console.log("Cart loaded");
    const response = await axios.get(`/api/customer/cart?clear=0`, {
      withCredentials: true, // Make sure this is set to true for CSRF
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },
  async getCartClear() {
    console.log("Cart loaded");
    const response = await axios.get(`/api/customer/cart?clear=1`, {
      withCredentials: true, // Make sure this is set to true for CSRF
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },
};

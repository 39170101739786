/* eslint-disable */
import axios from "axios";
import Cookies from "universal-cookie"; // for handling cookies
import { useAuthStore, useSearchStore, useLocationStore } from "../store"; // Import the Pinia store
const cookies = new Cookies();
const TOKEN_KEY = "authToken"; // Key for storing the token in localStorage
import { getBrowserUniqueId } from "@/utils/browser.util";
const isDevelopment = process.env.NODE_ENV === "development"; // Check if in development mode

function log(...args) {
  if (isDevelopment) {
    console.log(...args); // Log only in development mode
  }
}

export default {
  // Login method with Sanctum and CSRF handling
  async login(userCredentials) {
    try {
      // Get the CSRF token
      await axios.get("/sanctum/csrf-cookie", {
        withCredentials: true,
      });
      // Perform the login request
      const response = await axios.post(
        "/api/customer/auth/login",
        userCredentials,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"), // Pass the CSRF token
          },
        }
      );
      log("Login successful", response.data);
      log("Token:", response.data.token);
      // Set the token in localStorage (optional, if your API requires it)
      const token = response.data.token; // Adjust this if your API structure differs
      this.setToken(token);
      return response.data;
    } catch (error) {
      log(
        "Error validating OTP:",
        error.response ? error.response.data : error
      );
      // Return a structured response with error status
      return error.response.data;
    }
  },
  async verifyField(field, value) {
    try {
      const response = await axios.get("/api/seller/auth/verify-field", {
        params: { field, value },
      });
      return response.data; // Return the API response
    } catch (error) {
      console.error("Error verifying field:", error.response || error);
      return { status: "error", message: "Unable to verify at the moment." };
    }
  },
  // Logout method
  async logout() {
    try {
      // Get the current token from localStorage

      const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
      const token = storedData.authToken;
      const fcmToken = storedData.fcmToken; // Get the FCM token
      const deviceId = getBrowserUniqueId(); // Get the browser's unique ID
      log("Logging out...");
      log("FCM Token:", fcmToken);

      // Perform the logout request on the server using fetch
      await axios.get("/sanctum/csrf-cookie", { withCredentials: true });
      const response = await axios.post(
        "/api/customer/auth/logout_ck",
        { device_id: deviceId }, // Include fcmToken in the payload
        {
          withCredentials: true, // Make sure this is set to true for CSRF
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 204) {
        log("Logout successful");
        // Clear all user-related data from localStorage
        localStorage.removeItem("findhub_spa"); // Remove the specific key
        // Clear all cookies
        this.clearAllCookies(); // Ensure all cookies are cleared
        // Clear the Pinia store
        const authStore = useAuthStore();
        authStore.$reset();
        const searchStore = useSearchStore();
        searchStore.$reset();
        const locationStore = useLocationStore();
        locationStore.$reset();
      }

      localStorage.removeItem("findhub_spa");
      // Clear all user-related data from localStorage
      // Clear the Pinia store
      const authStore = useAuthStore();
      authStore.$reset();
      const searchStore = useSearchStore();
      searchStore.$reset();
      const locationStore = useLocationStore();
      locationStore.$reset();
    } catch (error) {
      log("Error logging out:", error);
      throw error; // Re-throw the error for further handling
    }
  },

  // Remove FCM token
  async removeFcmToken(fcmToken) {
    try {
      const response = await axios.post(
        "/api/customer/notification/delete_fcm_token",
        { token: fcmToken }, // Payload with the FCM token
        {
          withCredentials: true, // Ensure withCredentials is set to true
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"), // Include CSRF token
          },
        }
      );
      log("FCM token removed successfully:", response.data);
      return response.data;
    } catch (error) {
      log(
        "Error removing FCM token:",
        error.response ? error.response.data : error
      );
      throw error; // Re-throw the error for further handling
    }
  },

  // Signup method

  async signUp(userData) {
    console.log("userData:", userData);
    try {
      await axios.get("/sanctum/csrf-cookie", {
        withCredentials: true,
      });
      const response = await axios.post("/api/customer/auth/signup", userData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-xsrf-token": cookies.get("XSRF-TOKEN"),
        },
      });
      log("Signup successful", response.data);
      const token = response.data.token; // Adjust this if your API structure differs
      this.setToken(token);
      return response.data;
    } catch (error) {
      log("Signup failed", error.response ? error.response.data : error);
      throw error;
    }
  },

  async policyAccept(email, token) {
    try {
      // await axios.get("/sanctum/csrf-cookie", {
      //     withCredentials: true,
      // });
      const response = await axios.post(
        "/api/customer/auth/accept-policy",
        {
          email: email,
          policy_terms: "This is our new Policy for Customer",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"),
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      log("Error accepting policy:", error);
      throw error;
    }
  },

  // Clear client-side cookies
  clearAllCookies() {
    const allCookies = cookies.getAll(); // Get all the cookies
    Object.keys(allCookies).forEach((cookieName) => {
      cookies.remove(cookieName, { path: "/" }); // Remove each cookie
    });
    log("All cookies have been cleared.");
  },
  // Set token in localStorage
  setToken(token) {
    // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    // Save token in findhub_spa object
    const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
    storedData.authToken = token; // Store the token under authToken
    this.storeMultipleValues(storedData); // Use the existing function to store the updated object
  },

  setUserData(userData) {
    const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
    storedData.userData = userData; // Store the userData under userData key
    this.storeMultipleValues(storedData);
    // Use the existing function to store the updated object
  },
  // Remove token from localStorage and axios defaults
  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
    delete axios.defaults.headers.common["Authorization"];
  },
  // Check authentication status by querying the API
  async checkAuthStatus() {
    const authStore = useAuthStore(); // Get the Pinia store instance
    const isAuthenticated = authStore.isAuthenticated;
    const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
    const token = storedData.authToken;
    const googleAuth = localStorage.getItem("hello");
    const userData = storedData.userData; // Retrieve userData from storedData

    // log("Checking If Logged in:", isAuthenticated, token, googleAuth, userData);

    if (isAuthenticated || token) {
      try {
        const response = await axios.get("/api/customer/auth/me", {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          return response.data;
        }
      } catch (error) {
        log("Error checking auth status:", error);
      }
    } else if (googleAuth || userData) {
      try {
        let userDataObj;
        if (googleAuth) {
          const googleAuthData = JSON.parse(googleAuth);
          if (googleAuthData.google && googleAuthData.google.access_token) {
            // Check if userData is defined before parsing
            if (userData) {
              userDataObj = userData;
            }
          }
        } else if (userData) {
          userDataObj = JSON.parse(userData);
        }
        if (userDataObj && !authStore.user) {
          authStore.setUser(userDataObj);
        }
        return userDataObj;
      } catch (error) {
        log("Error parsing auth data:", error);
      }
    }

    return null;
  },

  async initiateOtpAuth(mobileNumber) {
    try {
      await axios.get("/sanctum/csrf-cookie", { withCredentials: true });
      const response = await axios.post(
        "/api/customer/auth/initial_auth",
        { mobile_number: mobileNumber },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"),
          },
        }
      );
      return response;
    } catch (error) {
      // log("Error sending OTP:", error.response ? error.response.data : error);
      throw error;
    }
  },

  async validateOtp(requestId, otp) {
    try {
      await axios.get("/sanctum/csrf-cookie", { withCredentials: true });
      const response = await axios.put(
        "/api/customer/auth/validate_auth",
        { request_id: requestId, otp: otp },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"),
          },
        }
      );
      return response;
    } catch (error) {
      log(
        "Error validating OTP:",
        error.response ? error.response.data : error
      );
      // Return a structured response with error status
      return error.response.data;
    }
  },
  async attempOAuth2Login(accessToken, type) {
    try {
      await axios.get("/sanctum/csrf-cookie", { withCredentials: true });
      const response = await axios.post(
        "/api/customer/auth/signup_oauth",
        {
          token: accessToken,
          type: type,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"),
          },
        }
      );
      log("OAuth2 login successful", response.data);
      this.storeOAuth2LoginResponse(response.data);
      return response.data;
    } catch (error) {
      log("OAuth2 login failed", error.response ? error.response.data : error);
      throw error;
    }
  },

  async getToken(requestId) {
    try {
      await axios.get("/sanctum/csrf-cookie", { withCredentials: true });
      const response = await axios.get("api/customer/auth/get_token", {
        params: { request_id: requestId },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-xsrf-token": cookies.get("XSRF-TOKEN"),
        },
      });
      // Store the token in local storage if the response is successful
      this.setToken(response.data.token); // Store the token using setToken
      return response; // Return the response
    } catch (error) {
      log("Error getting token:", error.response ? error.response.data : error);
      throw error;
    }
  },

  async signupRequest(data) {
    try {
      await axios.get("/sanctum/csrf-cookie", { withCredentials: true });
      const response = await axios.post(
        "api/customer/auth/signup_request",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"),
          },
        }
      );
      // Store the token in local storage if the response is successful
      const token = response.data.token; // Adjust this if your API structure differs
      this.setToken(token);
      return response.data; // Return the response
    } catch (error) {
      log("Error getting token:", error.response ? error.response.data : error);
      throw error;
    }
  },

  // Add this new function to store OAuth2 login response
  storeOAuth2LoginResponse(response) {
    const authStore = useAuthStore();
    log("OAuth2 login response:", response);
    // Save user data in Pinia store
    authStore.setUser(response.data);
    authStore.setAuthenticated(true);

    // Save user data in localStorage for persistence
    this.setUserData(response);
  },

  async getCurrentUser() {
    try {
      const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
      const token = storedData.authToken; // Get the token from findhub_spa
      const response = await axios.get("/api/customer/auth/me", {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        this.setUserData(response.data); // Save user data using setUserData
        return response.data;
      }
    } catch (error) {
      log("Error fetching current user:", error);
      throw error;
    }
  },

  async saveFcmToken(fcmToken) {
    // Check if the FCM token is already saved
    const deviceId = getBrowserUniqueId(); // Get the browser's unique ID
    const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};

    // Save deviceId to storedData

    if (storedData.fcmToken === fcmToken) {
      // log("FCM token already saved, skipping save.");
      this.storeMultipleValues(storedData); // Store updated values
      return; // Exit if the token is already saved
    }

    // Ensure the existing fcmToken is not overwritten
    if (storedData.fcmToken) {
      // log("Existing FCM token found, not overwriting.");
      this.storeMultipleValues(storedData); // Store updated values
      return; // Exit if an existing token is found
    }

    try {
      await axios.get("/sanctum/csrf-cookie", { withCredentials: true }); // Ensure CSRF token is fetched
      const token = storedData.authToken; // Get the Bearer token from stored data
      const response = await axios.post(
        "/api/customer/notification/token",
        { token: fcmToken, app_name: "WEB_CUSTOMER", device_id: deviceId }, // Payload with the FCM token
        {
          withCredentials: true, // Ensure withCredentials is set to true
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`, // Use Bearer token for authentication
            "x-xsrf-token": cookies.get("XSRF-TOKEN"), // Include CSRF token
          },
        }
      );
      log("FCM token saved successfully:", response.data);
      storedData.fcmToken = fcmToken; // Update the stored FCM token
      this.storeMultipleValues(storedData); // Store updated values
      return response.data;
    } catch (error) {
      log(
        "Error saving FCM token:",
        error.response ? error.response.data : error
      );
      throw error; // Re-throw the error for further handling
    }
  },

  storeMultipleValues(data) {
    localStorage.setItem("findhub_spa", JSON.stringify(data)); // Store all values under a single key
  },

  // New function to get nearby addresses based on latitude and longitude
  async getUserNearbyAddress(latitude, longitude) {
    const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
    const token = storedData.authToken; // Get the Bearer token from stored data
    try {
      const response = await axios.post(
        "/api/customer/address/find_nearby_address",
        {
          latitude: latitude,
          longitude: longitude,
        },
        {
          withCredentials: true, // Ensure withCredentials is set to true
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"),
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Check if the response contains an array and if it's empty
      if (Array.isArray(response.data) && response.data.length === 0) {
        return { message: "No addresses found." }; // Return message if no addresses found
      }

      return response.data; // Return the response data if addresses are found
    } catch (error) {
      log("Error fetching nearby addresses:", error);
      throw error; // Re-throw the error for further handling
    }
  },

  async getUserAddress() {
    const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
    const token = storedData.authToken; // Get the Bearer token from stored data
    try {
      const response = await axios.get("/api/customer/address", {
        withCredentials: true, // Ensure withCredentials is set to true
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-xsrf-token": cookies.get("XSRF-TOKEN"),
          Authorization: `Bearer ${token}`,
        },
      });
      // Check if the response contains an array and if it's empty
      if (Array.isArray(response.data) && response.data.length === 0) {
        return { message: "No addresses found." }; // Return message if no addresses found
      }

      return response.data; // Return the response data if addresses are found
    } catch (error) {
      log("Error fetching user addresses:", error);
      throw error; // Re-throw the error for further handling
    }
  },
  async setDefaultUserAddress(id) {
    const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
    const token = storedData.authToken; // Get the Bearer token from stored data
    try {
      const response = await axios.post(
        `/api/customer/address/${id}/set-default`,
        {}, // Empty object for the request body
        {
          withCredentials: true, // Ensure withCredentials is set to true
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"),
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      return response.data.message; // Return the response message
    } catch (error) {
      log("Error setting default user address:", error);
      throw error; // Re-throw the error for further handling
    }
  },

  async updateAddress(address) {
    const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
    const token = storedData.authToken; // Get the Bearer token from stored data
    try {
      const response = await axios.post(
        "/api/customer/address/modify",
        address, // Pass the address object as the request body
        {
          withCredentials: true, // Ensure withCredentials is set to true
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"),
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Return the response data to the caller
    } catch (error) {
      log("Error updating address:", error);
      throw error; // Re-throw the error for further handling
    }
  },

  async createNewAddress(address) {
    const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
    const token = storedData.authToken; // Get the Bearer token from stored data
    try {
      const response = await axios.post(
        "/api/customer/address",
        address, // Pass the address object as the request body
        {
          withCredentials: true, // Ensure withCredentials is set to true
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"),
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Return the response data to the caller
    } catch (error) {
      log("Error creating new address:", error);
      throw error; // Re-throw the error for further handling
    }
  },
  async updateUserProfile(data) {
    const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
    const token = storedData.authToken; // Get the Bearer token from stored data
    try {
      const response = await axios.post(
        "/api/customer/update-profile",
        data, // Pass the data object as the request body
        {
          withCredentials: true, // Ensure withCredentials is set to true
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"),
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Return the response data to the caller
    } catch (error) {
      log("Error updating user profile:", error);
      throw error; // Re-throw the error for further handling
    }
  },
  async requestResetPassword(email) {
    try {
      const response = await axios.post(
        "/api/customer/auth/send-password-reset-otp",
        {
          email: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"),
          },
        }
      );
      return response.data; // Return the response data to the caller
    } catch (error) {
        throw error.response.data; // Re-throw the error for further handling
    }
  },
  async verifyResetOTP(email, otp) {
    try {
      const response = await axios.post(
        "/api/customer/auth/verify-otp",
        {
          email: email,
          otp: otp,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"),
          },
        }
      );
      return response.data; // Return the response data to the caller
    } catch (error) {
      throw error.response.data; // Re-throw the error for further handling
    }
  },
  async resetPassword(email, password) {
    try {
      const response = await axios.post(
        "/api/customer/auth/change-password",
        {
          email: email,
          password: password,
          password_confirmation: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-xsrf-token": cookies.get("XSRF-TOKEN"),
          },
        }
      );
      return response.data; // Return the response data to the caller
    } catch (error) {
      throw error.response.data; // Re-throw the error for further handling
    }
  },
};

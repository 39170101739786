/* eslint-disable */
import axios from "axios";

const nodeServerUrl = process.env.VUE_APP_NODE_SERVER_API_URL || "http://localhost:3000"; // Set the base URL to the Vue.js current app URL


export const refCodeService = {
  async getReferenceCodes(classifiers) {
    const response = await axios.get(`/api/customer/reference_codes`, {
      params: {
        classifiers: classifiers.join(","),
      },
    });
    return response;
  },
  async getLocationHierarchy() {
    try {
      const response = await locationHierarchyAxios.get("/location-hierarchy", {
        withCredentials: false,
      });
      console.log("Location Hierarchy:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching location hierarchy:", error);
      throw error;
    }
  },
  async getLocationHierarchy() {
    try {
      const response = await axios.get(`${nodeServerUrl}/location-hierarchy`, {
        withCredentials: false,
      });
      console.log("Location Hierarchy:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching location hierarchy:", error);
      throw error;
    }
  },
};

import { createApp, markRaw } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import Buefy from "buefy";
import "./assets/scss/app.scss";
import "./registerServiceWorker";
import router from "./router";
import axios from "axios";
import "@mdi/font/css/materialdesignicons.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import PageLoader from "@/components/PageLoader.vue";
import { useAuthStore } from "./store";
import globalMixin from "./mixins";

// Configure Axios
const apiUrl = process.env.VUE_APP_API_URL;
axios.defaults.baseURL = apiUrl;
axios.defaults.withCredentials = true;

const app = createApp(App);
const pinia = createPinia();

// Apply the global mixin
app.mixin(globalMixin);

app.component("PageLoader", PageLoader);

pinia.use(({ store }) => {
  store.router = markRaw(router);
});

app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: "places", // Add any other libraries you need
    v: "weekly", // Specify the version, e.g., "weekly" for the latest
  },
});

app.config.globalProperties.$currentUser = () => {
  const authStore = useAuthStore();
  return authStore.isAuthenticated
    ? authStore.user
    : { id: null, name: "Not Authenticated" };
};

app.use(pinia);
app.use(router);
app.use(Buefy);
app.mount("#app");

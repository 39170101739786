import { defineStore } from "pinia";
import { refCodeService } from "@/services/refCodeService";

export const useCityselectStore = defineStore("cityselect", {
  state: () => ({
    locations: [], // Initially empty, will be populated from API
    selectedState: 1,
    selectedCity: null,
    selectedDistrict: null,
    selectedCountry: 1,
    business_type: [
      { code: "003", name: "Retail" },
      { code: "004", name: "Personal" },
      { code: "106", name: "Wholesale" },
      { code: "107", name: "Wholesale&Retail" },
    ],
    core_business: [
      { code: "005", name: "Electronics" },
      { code: "011", name: "Motor Vehicle" },
      { code: "013", name: "Mobile Phones & Accessories" },
      { code: "015", name: "Home Electronics" },
      { code: "023", name: "Food & Beverages" },
      { code: "025", name: "Health & Beauty" },
      { code: "027", name: "Home & Garden" },
      { code: "031", name: "Construction & Real Estate" },
      { code: "033", name: "Finance & Banking" },
      { code: "035", name: "Insurance & Superannuation" },
      { code: "037", name: "Media & Entertainment" },
      { code: "041", name: "Professional Services" },
      { code: "043", name: "Publishing & Advertising" },
    ],
  }),
  getters: {
    // Filter cities based on the selected state
    filteredCities(state) {
      if (!state.selectedCountry) {
        return [];
      }

      const filteredCountry = state.locations.find(
        (location) => String(location.id) === String(state.selectedCountry)
      );
      console.log(state.selectedState);
      // Check if the selected country exists and then find the state
      const filteredState = filteredCountry.states.find(
        (s) => s.id === String(state.selectedState)
      );
      console.log(filteredCountry.states);
      console.log(filteredState);

      return filteredState ? filteredState.cities : [];
    },
    // Filter districts based on the selected city
    filteredDistricts(state) {
      if (!state.selectedCity) return [];
      const selectedCity = state.filteredCities.find(
        (city) => city.id === state.selectedCity
      );
      return selectedCity ? selectedCity.districts : [];
    },
    getBusinessType(state) {
      return state.business_type;
    },
    getCoreBusiness(state) {
      return state.core_business;
    },
  },
  actions: {
    // Set selected state
    setSelectedState(stateId) {
      this.selectedState = stateId;
      this.selectedCity = null;
      this.selectedDistrict = null;
    },
    // Set selected city
    setSelectedCity(cityId) {
      this.selectedCity = cityId;
      this.selectedDistrict = null;
    },
    // Set selected district
    setSelectedDistrict(districtId) {
      this.selectedDistrict = districtId;
    },
    // Fetch hierarchical data from the API
    async fetchLocations() {
      try {
        const response = await refCodeService.getLocationHierarchy();
        // Map the API data into the required structure
        this.locations = response.map((country) => ({
          id: country.id,
          name: country.name,
          states: country.states.map((state) => ({
            id: state.id,
            name: state.name,
            cities: state.cities.map((city) => ({
              id: city.id,
              name: city.name,
            })),
          })),
        }));
      } catch (error) {
        console.error("Failed to fetch locations:", error);
      }
    },
  },
});

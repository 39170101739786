<template>
  <b-loading
    v-model="loader"
    :is-full-page="isFullPage"
    :can-cancel="true"
    @cancel="handleCancel"
  >
    <VueSpinnerHourglass size="40" color="red" />
  </b-loading>
</template>

<script>
import { VueSpinnerHourglass } from "vue3-spinners";

export default {
  name: "LoaderComponent",
  components: {
    VueSpinnerHourglass,
  },
  data() {
    return {
      loader: false,
    };
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    isFullPage: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleCancel() {
      this.$emit("update:modelValue", false); // Update the loading state
    },
  },
};
</script>

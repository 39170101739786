import axios from "axios";
import Cookies from "universal-cookie"; // for handling cookies
const cookies = new Cookies();

export class SellerService {
  static async saveSeller(reqData) {
    try {
      const response = await axios.post(`/api/seller/join_as_seller`, reqData, {
        headers: {
          "Content-Type": "application/json",
          "x-xsrf-token": cookies.get("XSRF-TOKEN"), // Pass the CSRF token/
        },
        withCredentials: true, // Enable withCredentials
      });
      return response;
    } catch (error) {
      return error.response.data; // Return error response data
    }
  }

  static async getAllCategories() {
    const response = await axios.get(`/api/seller/all-category`, {
      withCredentials: true, // Enable withCredentials
      headers: {
        "Content-Type": "application/json",
        "x-xsrf-token": cookies.get("XSRF-TOKEN"), // Pass the CSRF token/
      },
    });
    return response;
  }

  static async getAllSeller() {
    const response = await axios.get(`/api/customer/seller-directory`, {
      withCredentials: true, // Enable withCredentials
      headers: {
        "Content-Type": "application/json",
        "x-xsrf-token": cookies.get("XSRF-TOKEN"), // Pass the CSRF token/
      },
    });
    return response.data;
  }
}

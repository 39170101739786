//disable eslint
/* eslint-disable */

import { defineStore } from "pinia";
import authService from "../services/auth.service";
import { useSearchStore } from "./search";
import { useLocationStore } from "./location";
import storage from "@/utils/storage";
const isDevelopment = process.env.NODE_ENV === "development"; // Check if in development mode

function log(...args) {
  if (isDevelopment) {
    console.log(...args);
  }
}

// Add a flag to prevent multiple invocations
let isCheckingAuthStatus = false;

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: null,
    isAuthenticated: false,
    policyNotAccepted: false, // Policy acceptance status
    authErrors: [],
    userAddress: [], // Changed to an array
  }),
  getters: {
    // Get the current user's name or 'Not Authenticated'
    currentUser() {
      return this.isAuthenticated ? this.user : "Not Authenticated";
    },
    isUserAuthenticated() {
      return this.isAuthenticated; // Simply returns the state value
    },
  },
  actions: {
    setUser(userData) {
      this.user = userData;
      this.isAuthenticated = true;
    },
    setAuthenticated(status) {
      this.isAuthenticated = status;
    },
    async login(credentials) {
      try {
        const response = await authService.login(credentials);

        if (response.errors) {
          return response;
        } else if (response.token) {
          await this.getCurrentUser();
          return response;
        }
      } catch (error) {
        log("Login error:", error);
        this.authErrors.push(error); // Store error in authErrors array
        throw error;
      }
    },
    // async login(credentials) {
    //   try {
    //     const { token, user, policy_not_accepted } = await authService.login(
    //       credentials
    //     );
    //     this.user = user;
    //     this.isAuthenticated = true;
    //     this.policyNotAccepted = policy_not_accepted;
    //     storage.set("authToken", token);
    //     storage.set("userInfo", user);
    //     storage.set("policyNotAccepted", policy_not_accepted);

    //   } catch (error) {
    //      console.error("Login failed:", error);
    //      throw error;
    //   }
    // },
    async attempOAuth2Login(accessToken, type) {
      try {
        const response = await authService.attempOAuth2Login(accessToken, type);
        this.setUser(response); // Assuming response contains user data
        await this.setLocationSelector(); // Store the user addresses
        await this.checkRecentSearches();
        return response;
      } catch (error) {
        log("OAuth2 login error:", error);
        this.authErrors.push(error); // Store error in authErrors array
        throw error;
      }
    },
    async signUp(userData) {
      try {
        const response = await authService.signUp(userData);
        // Optionally set the user if the API returns user data upon signup
        await this.getCurrentUser();
        return response;
      } catch (error) {
        log("Signup error:", error);
        this.authErrors.push(error); // Store error in authErrors array
        throw error;
      }
    },
    async signupRequest(userData) {
      try {
        const response = await authService.signupRequest(userData);
        // Optionally set the user if the API returns user data upon signup
        // this.setUser(response.user);
        await this.getCurrentUser();
        return response;
      } catch (error) {
        log("Signup error:", error);
        this.authErrors.push(error); // Store error in authErrors array
        throw error;
      }
    },
    async logout() {
      try {
        await authService.logout();
        this.user = null;
        this.isAuthenticated = false;
        // localStorage.removeItem('userData');
        // localStorage.removeItem('hello');
        log("User is logged out");
      } catch (error) {
        log("Logout error:", error);
        this.authErrors.push(error); // Store error in authErrors array
      }
    },
    async checkAuthStatus() {
      console.log("Application Loaded Checking User Authentication Status");
      try {
        // Retrieve data from local storage
        // const storedData =
        //   JSON.parse(localStorage.getItem("findhub_spa")) || {};
        // const token = storedData.authToken; // Get the token from findhub_spa
        // const googleAuth = localStorage.getItem("hello");
        // const userData = storedData.userData; // Retrieve userData from storedData
        // // If no token found, mark as not authenticated
        // if (!token) {
        //   this.setAuthenticated(false);
        //   // log("No token found in local storage");
        //   return;
        // }
        // if (token && userData) {
        //   this.setAuthenticated(true);
        // }
        // if (googleAuth) {
        //   console.log("Found google auth token");
        //   this.setAuthenticated(true);

        // }
        // Call authService to check current user status using the token

        const response = await authService.checkAuthStatus();

        // If successful, update the user state with the response
        if (response && response.name) {
          // await this.setLocationSelector(); // Store the user addresses
          this.setUser(response);
        } else {
          this.setAuthenticated(false);
          log("Invalid token or user data");
        }

        // log("Auth status checked successfully:", response);
      } catch (error) {
        log("Error checking auth status:", error);
        this.authErrors.push(error);
        this.setAuthenticated(false);
      } finally {
        isCheckingAuthStatus = false;
      }
    },

    // async checkAuthStatus() {
    //   if (isCheckingAuthStatus) {
    //     return; // Exit if already checking
    //   }
    //   isCheckingAuthStatus = true; // Set the flag

    //   try {
    //     const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
    //     const token = storedData.authToken; // Get the token from findhub_spa
    //     const googleAuth = localStorage.getItem("hello");
    //     const userData = storedData.userData; // Retrieve userData from storedData

    //     // const user = await authService.checkAuthStatus();
    //     const searchStore = useSearchStore();

    //     // if (user) {
    //     //   this.setUser(user);
    //     //   const searchResult = await searchStore.viewAllSearchTerm(user.id);
    //     //   if (searchResult) {
    //     //     console.log("Found previous search results for the user");
    //     //   } else {
    //     //     console.log("No search results");
    //     //   }
    //     // } else {
    //     //   this.user = null;
    //     //   this.isAuthenticated = false;
    //     // }
    //     console.log("Checking user Authentication");

    //   } catch (error) {
    //     log("Error checking auth status:", error);
    //     this.authErrors.push(error); // Store error in authErrors array
    //     this.logout();
    //   } finally {
    //     isCheckingAuthStatus = false; // Reset the flag
    //   }
    // },

    async initiateOtpAuth(mobileNumber) {
      try {
        // log("Initiating OTP auth for mobile number:", mobileNumber);
        const response = await authService.initiateOtpAuth(mobileNumber);
        // log("Initiate OTP auth response:", response);
        return response.data.request_id;
      } catch (error) {
        
        this.authErrors.push(error); // Store error in authErrors array
        throw error;
      }
    },
    async validateOtp(requestId, otp) {
      try {
        let response = await authService.validateOtp(requestId, otp);
        // Check if response is structured correctly
        if (!response || !response.data) {
          return response; // Log the invalid response
          // throw new Error("Invalid response structure");
        }
        if (response.data.status === "VERIFIED" && response.data.is_user) {
          response = await this.getToken(requestId); // Await the getToken call
          // Store the token in local storage under findhub_spa
          // Assuming response.data contains the necessary data
          // Call the function to get the current user
          this.getCurrentUser();
          // Store the token in local storage for future requests
          // console.log("response from getToken", response);
          return response; // Return the response from getToken
        } else {
          console.error("Error in response data:", response); // Log the error response
          return response; // Return the response as it is if the condition is not met
        }
      } catch (error) {
        console.error("Error during OTP validation:", error); // Log the caught error
        this.authErrors.push(error); // Store error in authErrors array
      }
    },
    async getToken(requestId) {
      try {
        const tokenResponse = await authService.getToken(requestId);
        const token = tokenResponse.data.token;
        // localStorage.setItem("authToken", token);
        return tokenResponse; // Return the full tokenResponse to the requester

        // Call getCurrentUser instead of directly setting the user
        // await this.getCurrentUser()
      } catch (error) {
        log("Error getting token:", error);
        this.authErrors.push(error); // Store error in authErrors array
        return {
          status: "error", // Indicate an error occurred
          message: error.response
            ? error.response.data
            : "An unknown error occurred",
        };
      }
    },
    async getCurrentUser() {
      try {
        console.log(
          "Found cached user data , trying to retrieve user information"
        );
        const userData = await authService.getCurrentUser();
        // localStorage.setItem("userData", JSON.stringify(userData));
        // console.log(userData);
        // const userAddress = await this.getUserAddresses();
        // console.log(userAddress);
        // if (userAddress.length > 0) {
        //
        // }
        await this.setLocationSelector(); // Store the user addresses
        await this.checkRecentSearches();
        this.setUser(userData);
        return userData;
      } catch (error) {
        log("Error getting current user:", error);
        this.authErrors.push(error); // Store error in authErrors array
        throw error;
      }
    },
    async acceptPolicy(email, token) {
      try {
        const response = await authService.policyAccept(email, token);
        return response;
      } catch (error) {
        log("Error accepting policy:", error);
        this.authErrors.push(error); // Store error in authErrors array
        throw error;
      }
    },
    async getUserDataFromLocalStore() {
      const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {}; // Retrieve stored data
      return storedData.userData; // Return user data
    },
    async getUserNearbyAddresses(latitude, longitude) {
      try {
        const addresses = await authService.getUserNearbyAddress(
          latitude,
          longitude
        ); // Fetch nearby addresses with latitude and longitude
        // console.log(addresses);
        return addresses; // Return the fetched addresses
      } catch (error) {
        log("Error getting nearby addresses:", error);
        this.authErrors.push(error); // Store error in authErrors array
        throw error;
      }
    },
    async getUserAddresses() {
      // console.log("Checking for user addresses");
      try {
        const addresses = await authService.getUserAddress(); // Call the authService to get user addresses
        return addresses; // Return the addresses to the caller
      } catch (error) {
        log("Error getting user addresses:", error);
        this.authErrors.push(error); // Store error in authErrors array
        throw error;
      }
    },
    async setDefaultUserAddress(id) {
      try {
        const response = await authService.setDefaultUserAddress(id);
        return response; // Return the response to the caller
      } catch (error) {
        log("Error setting default user address:", error);
        this.authErrors.push(error); // Store error in authErrors array
        throw error; // Re-throw the error for further handling
      }
    },
    async updateProfile(data) {
      try {
        const response = await authService.updateUserProfile(data); // Call the authService to update user profile
        return response; // Return the response to the caller
      } catch (error) {
        log("Error updating user profile:", error);
        this.authErrors.push(error); // Store error in authErrors array
        throw error; // Re-throw the error for further handling
      }
    },
    async updateAddress(address) {
      try {
        const response = await authService.updateAddress(address);
        return response; // Return the response to the caller
      } catch (error) {
        log("Error updating address:", error);
        this.authErrors.push(error); // Store error in authErrors array
        throw error; // Re-throw the error for further handling
      }
    },
    async createNewAddress(address) {
      try {
        const response = await authService.createNewAddress(address);
        return response; // Return the response to the caller
      } catch (error) {
        log("Error creating new address:", error);
        this.authErrors.push(error); // Store error in authErrors array
        throw error; // Re-throw the error for further handling
      }
    },
    async setLocationSelector() {
      // console.log("Setting location selector Function called from authStore");
      const address = await this.getUserAddresses();
      const locationStore = useLocationStore();
      await locationStore.setLocationSelector(address);
    },
    async checkRecentSearches() {
      const searchStore = useSearchStore();
      return await searchStore.viewAllSearchTerm();
    },
    async requestResetPassword(email) {
      return await authService.requestResetPassword(email);
    },
    async verifyResetOTP(email, otp) {
      return await authService.verifyResetOTP(email, otp);
    },
    async changePassword(email, password) {
      return await authService.resetPassword(email, password);
    },
  },
});

// utils/browser.util.js
import { v4 as uuidv4 } from "uuid";

export function getBrowserUniqueId() {
  // Check if the findhub_spa object exists in localStorage
  let findhubSpa = JSON.parse(localStorage.getItem("findhub_spa")) || {};

  // Check if the unique ID exists in the findhub_spa object
  let uniqueId = findhubSpa.device_id;

  // const uniqueId2 = uuidv4();
  // console.log("Generated UUID:", uniqueId2);

  if (!uniqueId) {
    // Generate a new unique ID and save it in the findhub_spa object
    uniqueId = uuidv4();
    findhubSpa.device_id = uniqueId; // Store as device_id
    localStorage.setItem("findhub_spa", JSON.stringify(findhubSpa)); // Store updated object
  }

  return uniqueId;
}

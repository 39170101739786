<template>
  <Nav />
  <div class="container mt-5">
    <section class="hero is-primary">
      <div class="hero-body">
        <p class="title">About Findhub</p>
        <b-breadcrumb align="is-left" class="mt-3">
          <b-breadcrumb-item href="/">Home</b-breadcrumb-item>
          <b-breadcrumb-item href="/documentation/breadcrumb" active>
            About Us
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </section>

    <div class="content mt-6">
      <div class="content">
        <div class="columns is-vcentered">
          <div class="column is-one-fifth">
            <b-image
              src="/logos/Logo_Only@2x.png"
              placeholder="https://picsum.photos/id/237/200/100?blur=10"
              class="image findhub_about_logo mt-3"
            ></b-image>
          </div>
          <div class="column">
            <h5 class="title is-5 has-text-justified mt-2">
              Findhub is a venture between two friends who struggled to find the
              right product in the UAE market that they were searching for. To
              read about their struggle and what led to the creation of Findhub
              app
            </h5>
          </div>
        </div>
      </div>
      <div class="content">
        <h3 class="title is-3">Introduction</h3>
        <p class="subtitle is-5 has-text-justified">
          Founded by two colleagues in 2020, FindHub is just as the same
          suggests. An online UAE-based local search engine that uses the best
          of technology to bring you what you have been searching for. Minimum
          labour, easy use, and great accessibility are just some of the many
          features of our application. It doesn't get easier than this!
        </p>
      </div>
      <div class="content">
        <h3 class="title is-3">Origin Story</h3>
        <p class="subtitle is-5 mt-2 has-text-justified">
          2020 has been quite the year. The onset of a sudden pandemic and the
          subsequent lockdowns saw people confined to their houses with little
          or no avenue of stepping out or enjoying recreational activities such
          as shopping, picnics, etc.
        </p>
        <p class="subtitle is-5 has-text-justified">
          This particular lockdown also saw a sudden surge in the shopping
          frequency. As a result of this consumer shopping behaviour, we saw
          some unique patterns. People were now looking for products that they
          never had before or never needed to look for before. Most times they
          ended up with the wrong products that were shipped from far-off and
          unknown sources. We found ourselves in a similar predicament. We
          needed some very specific products that seemed out of reach. A local
          search for something small in the streets of Dubai turned into an
          expedition of sorts. We scoured every local market, combed through
          online platforms and stores, searched high and low in every lane, and
          continued to endeavour for a few days still.
        </p>
      </div>
      <p class="subtitle is-5 has-text-justified">
        Finally, we had to resort to online platforms with international
        shipping before we discovered a wholesale vendor who had the product,
        right down our lane! So we decided to do just that. We created our own
        application to help people search for all the products and services they
        require, from verified sources without any hassle or discomfort. Our
        necessity gave way to FindHub. Our application made way for a number of
        satisfied users and vendors, alike.
      </p>
    </div>
    <div class="columns is-vcentered py-5">
      <div class="column is-one-third fh_aboutus_section_cover">
        <figure class="image fh_aboutus_section_cover-img">
          <img src="/img/why_we_need.jpg" />
        </figure>
      </div>
      <div class="column">
        <h3 class="title fh_aboutus_section_cover-title is-3">
          Why Do We Need <br />
          A Local Search Engine?
        </h3>
        <p class="subtitle is-5 mt-2 has-text-justified">
          Search engine use accounts for 93% of the web traffic currently.
          Simply put, everyone is constantly looking for something or the other.
          We've faced that struggle. We know the pain that goes into looking for
          that important item. However, it always seems out of reach due to
          unavailability or a lack of demand in the market.
        </p>
      </div>
    </div>
    <div class="columns is-vcentered py-5">
      <div class="column">
        <h3 class="title is-3">Why Can't I Find What I Want Locally?</h3>
        <p class="subtitle is-5 mt-2 has-text-justified">
          This is the exact problem we wish to address. An e-commerce search
          engine with the potential to turn into the best e-commerce search
          engine in helping you look for what you need without having to resort
          to travelling far and wide for it. A new radiator for your car? A
          repairman for that 5-year old AC? All you have to do is type it in. In
          short, we provide local search-related services in the UAE.
        </p>
      </div>
      <div class="column is-one-third">
        <figure class="image">
          <img src="/img/why_cant_find_locally.jpg" />
        </figure>
      </div>
    </div>
    <div class="columns is-vcentered py-5">
      <div class="column is-one-third">
        <figure class="image">
          <img src="/img/mission.jpg" />
        </figure>
      </div>
      <div class="column">
        <h3 class="title is-3">What Is Our Mission?</h3>
        <p class="subtitle is-5 mt-2 has-text-justified">
          We facilitate growth by getting the right people in touch. Great
          things happen when you go looking for the perfect item only to find it
          in the oddest of places. What started as a game of hunt is now a whole
          new platform to find the right fit. If you are looking for a product
          or service that you cannot find anywhere nearby, well, now you know
          where to look!. Findhub makes your search easier.
        </p>
      </div>
    </div>
    <div class="columns is-vcentered py-5">
      <div class="column">
        <h3 class="title is-3">What Is Our Vision?</h3>
        <p class="subtitle is-5 mt-2 has-text-justified">
          People search for a million things a day. It can be an image search, a
          product search, or even a people search; there is nothing you cannot
          look for. We envision a future where this gap between a customer and a
          vendor is bridged successfully. We aim to be the best local search
          engine to find the exact thing you need.
        </p>
      </div>
      <div class="column is-one-third">
        <figure class="image">
          <img src="/img/vision.jpg" />
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
export default {
  components: {
    Nav,
  },
  name: "AboutUs",
  data() {
    return {};
  },
};
</script>

<style scoped>
.findhub_about_logo {
  max-width: 10rem;
}
.fh_aboutus-page_banner {
  /* background-image: url("../../../public/img/aboutus_banner_1.jpg"); */
  /* Update with your image path */
  background: linear-gradient(to bottom, #003b33, #007f6d);
  background-size: cover;
  /* Ensures the image covers the entire section */
  background-position: center;
  /* Centers the image */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light drop shadow */
}
.fh_aboutus_section_cover {
  position: relative;
}
.fh_aboutus_section_cover-img {
}
.fh_aboutus_section_cover-title {
}
.container {
  max-height: 80vh; /* Set a maximum height for the container */
  overflow-y: auto; /* Enable vertical scrolling when content exceeds max height */
}

/* Hide scrollbar for WebKit browsers (Chrome, Safari) */
.container::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

/* Hide scrollbar for Firefox */
.container {
  scrollbar-width: none; /* Hide scrollbar */
}

/* Hide scrollbar for Internet Explorer and Edge */
.container {
  -ms-overflow-style: none; /* Hide scrollbar */
}
</style>

<template>
  <section>
    <b-loading :is-full-page="true" :active="loading"></b-loading>

    <div class="chat-container">
      <!-- Chat Messages -->
      <div class="chat-messages" ref="chatMessagesContainer">
        <b-message
          v-for="(message, index) in chat.messages.data"
          :key="index"
          :title="message.sender_name"
        >
          {{ message.body }}
        </b-message>
      </div>

      <!-- Chat Input -->
      <b-field>
        <b-input
          v-model="chatForm.data.body"
          placeholder="Type your message..."
          @keyup.enter="onChatSubmit"
        ></b-input>
      </b-field>
      <b-button
        :loading="chatForm.loading"
        type="is-primary"
        @click="onChatSubmit"
        >Send</b-button
      >
    </div>
  </section>
</template>

<script>
import { SupportChatService } from "@/services/support-chat.services";
import Echo from "laravel-echo";
import axios from "axios";
import Cookies from "universal-cookie"; // for handling cookies

const cookies = new Cookies();
const storedData = JSON.parse(localStorage.getItem("findhub_spa")) || {};
const token = storedData.authToken; // Get the token from findhub_spa

export default {
  name: "SupportChat",
  data() {
    return {
      loading: true,
      chat: {
        thread: null,
        messages: {
          data: [],
        },
        pusher: null,
        echo: null,
      },
      chatForm: {
        data: {
          thread_id: null,
          body: "",
        },
        loading: false,
      },
    };
  },
  async mounted() {
    try {
      this.loading = true;
      console.log("Fetching active chat thread...");
      this.chat.thread = await SupportChatService.getActiveThread();
      this.chatForm.data.thread_id = this.chat.thread.id;
      console.log("Active thread ID:", this.chat.thread.id);

      // Setup Pusher and Echo
      console.log("Setting up Pusher and Echo...");
      this.chat.pusher = require("pusher-js");
      this.chat.echo = new Echo({
        broadcaster: "pusher",
        key: "0831ba376a02083bbb94",
        cluster: "ap2",
        forceTLS: true,
        authEndpoint: `/api/customer/auth/ws`,
        authorizer: (channel) => {
          return {
            authorize: (socketId, callback) => {
              console.log(
                `Authorizing channel: ${channel.name}, socketId: ${socketId}`
              );
              axios
                .post(
                  `/api/customer/broadcasting/auth`,
                  {
                    socket_id: socketId,
                    channel_name: channel.name,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "X-XSRF-TOKEN": cookies.get("XSRF-TOKEN"), // Correct capitalization for CSRF token
                    },
                  }
                )
                .then((response) => {
                  console.log("Authorization successful:", response.data);
                  callback(false, response.data);
                })
                .catch((error) => {
                  console.error("Authorization failed:", error);
                  callback(true, error);
                });
            },
          };
        },
      });

      // Listen to private channel for chat messages
      console.log(
        "Subscribing to private channel: f.support-chat." + this.chat.thread.id
      );
      this.chat.echo
        .private(`f.support-chat.${this.chat.thread.id}`)
        .listen("SupportChatAdminMessaged", (e) => {
          console.log("Received message from private channel Here :", e);
          console.log(e.body);
          console.log(e.user.name);
          // Adjusted to access the correct data structure
          if (e && e.user.name && e.body) {
            console.log("Received message from private channel");
            const message = {
              sender_name: e.user.name,
              body: e.body,
              // Add any other properties you need from e.data
            };
            this.chat.messages.data.unshift(message);
            console.log(message);
          } else {
            console.error(
              "Received message is missing required properties:",
              e
            );
          }
        })
        .on("subscription_succeeded", () => {
          console.log("Successfully subscribed to private channel");
        })
        .on("subscription_error", (error) => {
          console.error("Subscription error:", error);
        });

      // Load previous messages
      console.log("Fetching previous messages...");
      this.chat.messages = await SupportChatService.getThreadMessages(
        this.chat.thread.id
      );
    } catch (error) {
      console.error("Error in SupportChat:", error);
    } finally {
      this.loading = false;
      this.scrollToElement();
    }
  },
  updated() {
    this.$nextTick(() => {
      this.scrollToElement();
    });
  },
  methods: {
    scrollToElement() {
      const el = this.$refs.chatMessagesContainer;
      if (el) {
        el.scrollTop = el.scrollHeight;
      }
    },
    async onChatSubmit() {
      const formData = this.chatForm.data;
      if (!formData.body.length > 0) {
        return;
      }
      try {
        this.chatForm.loading = true;
        console.log("Sending message:", formData.body);
        const message = await SupportChatService.postNewMessage(
          this.chatForm.data
        );
        console.log("Message sent:", message);
        this.chat.messages.data.unshift(message);
        this.chatForm.data.body = "";
      } finally {
        this.chatForm.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.chat-container {
  max-width: 600px;
  margin: 0 auto;
}

.chat-messages {
  height: 400px;
  overflow-y: scroll;
  margin-bottom: 20px;
}
</style>

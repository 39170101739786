import { defineStore } from "pinia";
import { pageServices } from "@/services/page.service";

export const usePageStore = defineStore("page", {
  state: () => ({
    allCategories: [],
  }),
  actions: {
    async getAllCategories() {
      // console.log("async getAllCategories");
      const categories = await pageServices.getAllCategories();
      this.allCategories = categories;
      return this.allCategories;
    },
  },
});

import { defineStore } from "pinia";
import cartService from "@/services/cart.service";

export const useCartStore = defineStore("cart", {
  state: () => ({
    cart: [],
  }),
  actions: {
    async addToCart(product) {
      const updatedCart = await cartService.addToCart(product);
      this.cart = updatedCart;
      return updatedCart;
    },
    async removeFromCart(productId) {
      const data = {
        cart_id: this.cart.id,
        cart_item_id: productId,
      };
      const updatedCart = await cartService.removeFromCart(data);
      console.log(updatedCart);
    },
    async clearCart() {
      await cartService.clearCart();
      this.cart = [];
    },
    async getCart() {
      const cart = await cartService.getCart();
      this.cart = cart;
      return cart;
    },
    async getNewCart() {
      const cart = await cartService.getCartClear();
      this.cart = cart;
      return cart;
    },
  },
  getters: {
    itemCount: (state) =>
      state.cart.cart_items ? state.cart.cart_items.length : "",
    totalAmount: (state) =>
      state.cart.cart_items && state.cart.cart_items.length > 0
        ? state.cart.cart_items.reduce(
            (total, item) => total + item.total_price,
            0
          )
        : "",
    cartItems: (state) => state.cart.cart_items || [],
  },
});

<template>
  <Nav />
  <div class="container">
    <section class="hero is-primary">
      <div class="hero-body">
        <p class="title">Register Seller</p>
        <b-breadcrumb align="is-left">
          <b-breadcrumb-item href="/">Home</b-breadcrumb-item>
          <b-breadcrumb-item href="/documentation/breadcrumb" active>
            Join As Seller
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </section>
    <b-field position="is-right" class="mt-3">
      <p class="control">
        <b-dropdown v-model="selectedSort">
          <template #trigger>
            <b-button label="Sort" icon-right="menu-down" />
          </template>

          <b-dropdown-item value="open_issues"
            >Best Rated Seller</b-dropdown-item
          >
          <b-dropdown-item value="your_issues">Electronics</b-dropdown-item>
          <b-dropdown-item value="pull_requests">Automobile</b-dropdown-item>
          <b-dropdown-item value="everything">Mobile Phone</b-dropdown-item>
        </b-dropdown>
      </p>
      <b-input
        v-model="searchQuery"
        icon="magnify"
        type="search"
        placeholder="Search..."
      ></b-input>
    </b-field>
    <div class="seller-cards fh_seller_directory_main-wrap">
      <div>
        <div class="columns fh_seller_diretory_card-wrap is-flex-wrap-wrap">
          <div
            class="column fh_seller_diretory_card"
            v-for="seller in filteredSellers"
            :key="seller.id"
          >
            <div
              class="box fh_seller_diretory_card-box hover-zoom"
              @click="openModal(seller)"
            >
              <div class="fh_seller_diretory_card-box-business">
                <span class="fh_seller_directory_card-box-business_text">
                  {{ seller.core_business }}
                </span>
              </div>
              <div class="fh_seller_diretory_card-box-business-time">
                <img
                  src="/icons/shop_time.png"
                  alt="Small Image"
                  class="small-image"
                />
                <p class="small-image-text">
                  {{ formatTime(seller.starting_time) }} -
                  {{ formatTime(seller.closing_time) }}
                </p>
              </div>
              <div class="fh_seller_diretory_card-box-business-plan">
                <img
                  src="/icons/basic.png"
                  alt="Small Image"
                  class="small-image-1"
                />
                <p class="small-image-text-1">
                  {{ seller.subscription?.plan_name || "N/A" }}
                </p>
              </div>
              <article class="media fh_seller_diretory_card-box-content">
                <figure class="media-left">
                  <p
                    class="image is-64x64"
                    style="display: flex; align-items: center"
                  >
                    <template v-if="seller.logo">
                      <img :src="imageUrl(seller.logo)" />
                    </template>
                    <template v-else>
                      <div class="avatar-initials">
                        {{
                          seller.business_name
                            .split(" ")
                            .slice(0, 2)
                            .map((name) => name.charAt(0))
                            .concat(
                              seller.business_name.split(" ").length > 2
                                ? seller.business_name.split(" ")[2].charAt(0)
                                : ""
                            )
                            .slice(0, 2)
                            .join("")
                            .toUpperCase()
                        }}
                      </div>
                    </template>
                  </p>
                </figure>
                <div class="media-content">
                  <div class="content is-small">
                    <h4 class="mb-0">{{ seller.business_name }}</h4>
                    <div>
                      <span class="tag is-rounded">{{
                        seller.business_type
                      }}</span>
                    </div>
                    <div>
                      <span class="tag is-light"
                        >{{ seller.state_name }} |
                        {{ seller.country_name }}</span
                      >
                    </div>
                    <div>
                      <div class="columns">
                        <div class="column">
                          <b-rate
                            v-model="seller.average_rating"
                            disabled
                          ></b-rate>
                        </div>
                        <div class="column">
                          <span
                            >{{ seller.approved_reviews_count }} Reviews</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="div">
                      <a
                        v-if="seller.latitude && seller.longitude"
                        :href="`https://www.google.com/maps?q=${seller.latitude},${seller.longitude}`"
                        target="_blank"
                        class="google-map-link"
                      >
                        View on Google Maps
                      </a>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */ 
import { SellerService } from "@/services/seller.service";
import Nav from "@/components/Nav.vue";
import SellerDetailsModal from "@/components/SellerDetailsModal.vue";

export default {
  data() {
    return {
      sellers: [],
      searchQuery: "",
      selectedSort: "name",
      isCardModalActive: false,
      isModalActive: false,
      selectedSeller: null, // To hold the selected seller
    };
  },
  components: {
    Nav,
    SellerDetailsModal,
  },
  computed: {
    filteredSellers() {
      return this.sellers
        .filter((seller) =>
          seller.business_name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        )
        .sort((a, b) => {
          if (this.selectedSort === "name") {
            return a.business_name.localeCompare(b.name);
          } else if (this.selectedSort === "rating") {
            return b.rating - a.rating;
          }
          return 0;
        });
    },
    sellerPlanNames() {
      return this.sellers.map(
        (seller) => seller.subscription?.plan_name || "N/A"
      );
    },
  },
  methods: {
    async fetchSellers() {
      try {
        this.sellers = await SellerService.getAllSeller();
        console.log(this.sellers); // Log the fetched sellers
      } catch (error) {
        console.error("Error fetching sellers:", error);
      }
    },
    openModal(seller) {
      console.log("model");
      this.$buefy.modal.open({
        parent: this,
        component: SellerDetailsModal,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
        props: { seller },
      });
    },
  },
  mounted() {
    this.fetchSellers();
  },
};
</script>

<style scoped>
.fh_seller_diretory_card {
  max-width:30rem;
}
.fh_seller_diretory_card-box {
  position: relative;
  width: 26rem;
  height: 8rem;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.hover-zoom:hover {
  transform: scale(1.02) translateY(-5px);
}

.fh_seller_diretory_card-box-content {
  position: absolute;
  top: 14px;
  left: 50px;
}

.fh_seller_diretory_card-box-business {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00a991;
  height: 100%;
  width: 2.5rem;
  overflow: hidden;
  border-radius: 0.4rem 0rem 0rem 0.4rem;
  padding: 0.6rem 0rem;
}

.fh_seller_directory_card-box-business_text {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 0.8rem;
  width: 100%;
  font-weight: 800;
  line-height: 0.8rem;
  color: #d9f2ef;
}

.avatar-initials {
  background-color: #00a991; /* Change to your desired color */
  color: #ffffff; /* Text color */
  font-size: 1.8rem; /* Large font size */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px; /* Match the image size */
  height: 64px; /* Match the image size */
  border-radius: 50%; /* Make it circular */
}

.fh_seller_diretory_card-box-business-time {
  position: absolute;
  bottom: 0.3rem;
  left: 3.5rem;
  text-align: center;
}
.fh_seller_diretory_card-box-business-plan {
  position: absolute;
  top: 1.3rem;
  right: 1.5rem;
  text-align: center;
}

.small-image {
  width: 2rem;
}
.small-image-text {
  font-size: 0.5rem;
  line-height: 0.5rem;
  margin-top: -0.5rem;
  margin-bottom: 0.2rem;
}

.small-image-1 {
  width: 3rem;
}

.small-image-text-1 {
  font-size: 0.8rem;
  line-height: 0.5rem;
  font-weight: 800;
  margin-top: -0.5rem;
}
.fh_seller_diretory_card-wrap {
    padding: .3rem .2rem;
}
.fh_seller_directory_main-wrap {
height: 80vh; 
overflow: auto; 
scrollbar-width: none; 
-ms-overflow-style: none
}
</style>

/* eslint-disable */
<template>
  <div>
    <form action="">
      <div class="modal-card fh_login_modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Login</p>
          <button type="button" class="delete" @click="$emit('close')" />
        </header>
        <section class="modal-card-body">
          <!-- Added login type selection -->
          <!-- <b-field label="Select Login Type">
          <b-select v-model="loginType">
            <option value="email">Email Login</option>
            <option value="otp">OTP Login</option>
          </b-select>
        </b-field> -->
          <p class="buttons">
            <button
              class="button"
              :class="{ 'is-active': loginType === 'otp' }"
              @click.prevent="loginType = 'otp'"
              :disabled="isOtpSent"
            >
              <span class="icon">
                <i class="mdi mdi-cellphone"></i>
              </span>
              <span>Mobile</span>
            </button>

            <button
              class="button"
              :class="{ 'is-active': loginType === 'email' }"
              @click.prevent="loginType = 'email'"
              :disabled="isOtpSent"
            >
              <span class="icon">
                <i class="mdi mdi-email"></i>
              </span>
              <span>Email</span>
            </button>

            <button
              class="button"
              :class="{ 'is-active': loginType === 'gmail' }"
              @click.prevent="
                loginType = 'gmail';
                handleGoogleLogin();
              "
              :disabled="isOtpSent"
            >
              <span class="icon">
                <i class="mdi mdi-gmail"></i>
              </span>
              <span>Gmail</span>
            </button>
          </p>

          <!-- Conditional rendering based on login type -->
          <template v-if="loginType === 'email'">
            <b-field
              label="Email"
              :type="errorMessages.email ? 'is-danger' : ''"
              :message="errorMessages.email"
            >
              <b-input
                type="email"
                v-model="signInForm.email"
                placeholder="Your email"
                required
                :disabled="isAwaitingCode"
              />
            </b-field>
            <b-field
              label="Password"
              class="mb-1"
              :type="errorMessages.email ? 'is-danger' : ''"
              :message="errorMessages.email"
            >
              <b-input
                type="password"
                v-model="signInForm.password"
                password-reveal
                placeholder="Your password"
                required
                :message="errorMessages.email"
              />
            </b-field>
            <p class="is-link has-text-right">
              <span>
                <a href="" @click.prevent="loginType = 'signUpAccount'"
                  >Sign Up</a
                ></span
              >
              |
              <a href="" @click.prevent="loginType = 'forgotEmailPassword'"
                >Forgot Password</a
              >
            </p>
            <div class="buttons is-center">
              <b-button
                label="Login"
                type="is-primary mt-3"
                @click="handleEmailLogin"
              />
            </div>
          </template>

          <template v-else-if="loginType === 'otp'">
            <div v-if="!isOtpSent" class="">
              <p class="mb-3">
                Please enter any valid UAE mobile number to Login
              </p>

              <b-field
                class=""
                :type="errorMessages.otp ? 'is-danger' : ''"
                :message="errorMessages.otp ? errorMessages.otp : ''"
              >
                <div class="control has-icons-left">
                  <div class="select">
                    <select>
                      <option selected>UAE(+971)</option>
                    </select>
                  </div>
                  <div class="icon is-small is-left">
                    <i class="mdi mdi-earth"></i>
                  </div>
                </div>
                <b-input
                  type="tel"
                  v-model="mobile_number"
                  v-mask="'## ### ####'"
                  placeholder="5# ### #####"
                  @keyup.enter="handleInitialAuth"
                ></b-input>
                <p class="control">
                  <b-button
                    type="is-primary"
                    label="Send"
                    :class="{ 'is-loading': OTPisLoading }"
                    @click="handleInitialAuth"
                  />
                </p>
              </b-field>
            </div>

            <template v-if="isOtpSent && loginType !== 'signUpRequest'">
              <div class="div">
                <b-field class="fh_otp_login">
                  <v-otp-input
                    ref="otpInput"
                    input-classes="otp-input"
                    :conditionalClass="['one', 'two', 'three', 'four']"
                    separator="-"
                    inputType="numeric"
                    :num-inputs="4"
                    v-model:value="otp"
                    :should-auto-focus="true"
                    :should-focus-order="true"
                    @on-complete="handleOtpValidation"
                    :placeholder="['#', '#', '#', '#']"
                    :is-disabled="isOTPDisabled"
                  />
                </b-field>
                <div v-if="errorMessages.otp" class="has-text-centered">
                  <p class="help is-danger">
                    {{ errorMessages.otp }}
                  </p>
                  <a href="" @click.prevent="clearOtpInput()">Retry</a>
                </div>

                <b-field class="has-text-centered">
                  <a href="" @click.prevent="handleInitialAuth"
                    >No Received ?</a
                  >
                </b-field>
              </div>
            </template>
          </template>

          <template v-else-if="loginType === 'forgotEmailPassword'">
            <div v-if="passwordResetProcess === 'started'">
              <b-field
                label="Email"
                :type="passwordReset.emailSent ? 'is-primary' : ''"
                :message="
                  passwordReset.emailSent ? 'Email sent to your mailbox' : ''
                "
              >
                <b-input
                  type="email"
                  v-model="passwordReset.email"
                  placeholder="Your email"
                  required
                  :disabled="isAwaitingCode"
                />
              </b-field>
              <p
                v-if="passwordReset.errorMessages.email"
                class="has-text-danger"
              >
                {{ passwordReset.errorMessages.email }}
              </p>
              <div class="buttons is-center" type="is-success">
                <b-button
                  label="Send Reset Password Link"
                  type="is-primary mt-3"
                  @click="handleForgotPassword"
                />
              </div>
            </div>
            <div v-if="passwordResetProcess === 'emailSent'">
              <b-field
                label="Enter Code"
                :type="passwordReset.errorMessages.otp ? 'is-danger' : ''"
                :message="
                  passwordReset.errorMessages.otp
                    ? passwordReset.errorMessages.otp
                    : ''
                "
              >
                <v-otp-input
                  ref="otpInput"
                  input-classes="otp-input"
                  :conditionalClass="['one', 'two', 'three', 'four']"
                  separator="-"
                  inputType="numeric"
                  :num-inputs="4"
                  v-model:value="passwordReset.otp"
                  :should-auto-focus="true"
                  :should-focus-order="true"
                  @on-complete="handleVerifyCode"
                  :placeholder="['#', '#', '#', '#']"
                  :is-disabled="isOTPDisabled"
                />
              </b-field>
              <div class="buttons is-center">
                <b-button
                  label="Verify Code"
                  type="is-primary mt-3"
                  @click="handleVerifyCode"
                />
              </div>
            </div>
            <div v-else-if="passwordResetProcess === 'otpVerified'">
              <b-field
                label="Password"
                class="mb-1"
                :type="passwordReset.errorMessages.password ? 'is-danger' : ''"
                :message="passwordReset.errorMessages.password"
              >
                <b-input
                  type="password"
                  v-model="passwordReset.password"
                  password-reveal
                  placeholder="Your password"
                  required
                  @blur="validatePasswords"
                />
              </b-field>
              <b-field
                label="Confirm Password"
                class="mb-1"
                :type="passwordReset.errorMessages.password ? 'is-danger' : ''"
                :message="passwordReset.errorMessages.password"
              >
                <b-input
                  type="password"
                  v-model="passwordReset.password_confirmation"
                  password-reveal
                  placeholder="Confirm password"
                  required
                  @blur="validatePasswords"
                />
              </b-field>
              <div class="buttons is-center">
                <b-button
                  label="Change Password"
                  type="is-primary mt-3"
                  @click="changePassword"
                />
              </div>
            </div>
            <div v-else-if="passwordResetProcess === 'changed'">
              <p class="has-text-centered">
                Your password has been successfully changed.
              </p>
              <div class="buttons is-center">
                <b-button
                  label="Login"
                  type="is-primary mt-3"
                  @click="loginType = 'email'"
                />
              </div>
            </div>
          </template>
          <template v-else-if="loginType === 'signUpAccount'">
            <b-field label="Name">
              <b-input
                type="text"
                v-model="signUpForm.name"
                placeholder="Your name"
                required
              />
            </b-field>
            <b-field label="Email">
              <b-input
                type="email"
                v-model="signUpForm.email"
                placeholder="Your email"
                required
              />
            </b-field>
            <b-field label="Password">
              <b-input
                type="password"
                v-model="signUpForm.password"
                password-reveal
                placeholder="Your password"
                required
              />
            </b-field>
            <b-field>
              <b-checkbox v-model="signUpForm.policy_accepted"></b-checkbox>
              I agree to the &nbsp;
              <a href="" @click.prevent="openTermsDialog(signUpForm)"
                >terms and conditions</a
              >
            </b-field>
            <div class="buttons is-center">
              <b-button
                label="Sign Up"
                type="is-primary mt-3"
                @click="handleSignUp"
                :disabled="!signUpForm.policy_accepted"
              />
            </div>
          </template>
          <template v-else-if="loginType === 'signUpRequest'">
            <p>Please update your profile information</p>
            <b-field label="Name">
              <b-input
                type="text"
                v-model="signUpRequestForm.name"
                placeholder="Your name"
                required
              />
            </b-field>
            <b-field label="Email">
              <b-input
                type="email"
                v-model="signUpRequestForm.email"
                placeholder="Your email"
                required
              />
            </b-field>
            <b-field>
              <b-checkbox v-model="signUpRequestForm.policy_accepted">
                I agree to the &nbsp;
                <a href="#" @click.prevent="openTermsDialog(signUpRequestForm)">
                  terms and conditions</a
                >
              </b-checkbox>
            </b-field>
            <div class="buttons is-center">
              <b-button
                label="Update Information"
                type="is-primary mt-3"
                @click="handleSignUpRequest"
                :disabled="!signUpRequestForm.policy_accepted"
              />
            </div>
          </template>
        </section>

        <footer class="modal-card-foot">
          <!-- <b-button type="is-primary" expanded><strong>Sign up</strong></b-button> -->
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
/* eslint-disable */
import { useAuthStore, useModalStore } from "@/store";
import VOtpInput from "vue3-otp-input";
import * as hellojs from "hellojs";
import { mask } from 'vue-the-mask'; // Import the mask directive
import { ref } from "vue";

hellojs.init({
  google: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  redirect_uri: '/oauth-redirect',
})

export default {
  components: {
    VOtpInput,
  },
  directives: { mask },
  data() {
    return {
      isComponentModalActive: false,
      loginType: 'otp', // New property to track login type
      mobile_number: "",
      otp: "",
      isOtpSent: false,
      isOTPDisabled: false,
      requestId: null,
      loading: false,
      errorMessages: {
        otp: "",
        email: ""
      },
      token: "",
      OTPisLoading: false,
      passwordResetProcess: "started",
      signInForm: {
        email: "",
        password: "",
        device_name: "Web",
      },
      signUpForm: {
        name: "",
        email: "",
        password: "",
        policy_accepted: false,
        policy_terms: "I Accepts Findhub's Customer Policy of 2024"
      },
      signUpRequestForm: {
        name: "",
        email: "",
        request_id: "",
        mode: "token",
        policy_accepted: false,
        policy_terms: "I Accepts Findhub's Customer Policy of 2024"
      },
      passwordReset: {
        email: "",
        emailSent: false,
        otp: "",
        password: "",
        password_confirmation: "",
        errorMessages: {
          password: "",
          email: "",
          otp: "",
        }
      },
      agreeTerms: false,
      isAwaitingCode: false,
      resetCode: "",
    };
  },
  setup() {
    const authStore = useAuthStore();
    const modalStore = useModalStore();


    return { modalStore, authStore };
  },
  computed: {

  },
  methods: {
    logout() {
      this.authStore.logout();
      this.$router.push("/");
    },
    sanitizePhoneNumber(number) {
      return number.replace(/\D/g, ''); // Remove all non-digit characters
    },
    clearOtpInput() {
      // Use the otpInput ref to call the clearInput method
      if (this.$refs.otpInput) {
        this.$refs.otpInput.clearInput();
      }
    },
    async handleInitialAuth() {
      this.OTPisLoading = true;
      const cleaned_mobile_number = this.sanitizePhoneNumber(this.mobile_number);
      try {
        const response = await this.authStore.initiateOtpAuth(
          cleaned_mobile_number
        );
        this.isOtpSent = true;
        this.requestId = response
        this.signUpRequestForm.request_id = response; // Assuming response has requestId
      } catch (error) {
        if (error.response.data.errors.mobile_number) {
          this.errorMessages.otp = "Invalid mobile number. Mobile number must start with 5 and contain exactly 8 digits."
        }
        console.log(this.errorMessages); //
      } finally {
        this.OTPisLoading = false;
      }
    },
    validatePasswords() {
      if (this.passwordReset.password !== this.passwordReset.password_confirmation) {
        this.passwordReset.errorMessages.password = "Passwords do not match.";
      } else {
        this.passwordReset.errorMessages.password = null; // Clear the error message when passwords match
      }
    },

    async handleOtpValidation() {
      this.isDisabled = true;
      this.loading = true;
      try {
        const response = await this.authStore.validateOtp(
          this.requestId,
          this.otp
        );
        console.log(response.error);

        // Check for error status
        if (response.errors) {

          this.errorMessages.otp = "Invalid OTP. Please try again.";
          console.log(this.errorMessages)
          return; // Exit the function if there's an error
        }

        // Check for policy acceptance
        if (response.data.policy_not_accepted) {
          console.log("Policy Not Accepted. Please try again.");
          this.policyAcceptance(response.data.token, response.data.email); // Call policyAcceptance with token and email
        } else if (response.data.is_user === false) { // Check if user is not registered
          this.loginType = 'signUpRequest'; // Change login type to sign up
          this.requestId = response.data.request_id; // Set requestId from response
        } else {
          console.log(this.authStore.isAuthenticated);
          this.$emit('close');
          setTimeout(() => {
            const redirectRoute = this.modalStore.intendedRoute || '/';
            this.$router.push(redirectRoute); // Push the route after login
          }, 1500); // Delay of 1000 milliseconds (1 second)
        }
      } catch (error) {
        console.error("Error during OTP validation:", error.message); // Log the error message
      } finally {
        this.loading = false;
      }
    },
    async handleEmailLogin() {
      this.loading = true;
      try {
        const response = await this.authStore.login(this.signInForm);

        if (response.errors) {

          this.errorMessages.email = "Invalid Credentials. Please try again.";
          return; // Exit the function if there's an error
        }
        console.log(response); //
        if (response.policy_not_accepted) {
          console.log("response", response);
          console.log("policy_not_accepted");
          this.policyAcceptance(response.token, response.email);
        } else {
          console.log("policy_accepted");
          this.$emit('close');
          const redirectRoute = this.modalStore.intendedRoute || '/';
          this.$router.push(redirectRoute); // Push the route after login
        }
      } catch (error) {
        this.errorMessage = "Login failed. Please try again.";
      } finally {
        this.loading = false;
      }
    },
    openTermsDialog(form) {
      console.log("Clicked : openTermsDialog")
      this.$buefy.dialog.confirm({
        title: 'Privacy Policy',
        message: `<div style="text-align: justify;">
        Findhub Privacy Policy<br><br>

        Effective Date: [Insert Date]<br><br>

        At Findhub, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website [insert website URL], including any other media form, media channel, mobile website, or mobile application related or connected thereto.<br><br>

        1. **Information We Collect**<br>
        We may collect information about you in a variety of ways, including:<br>
        - Personal Data: Personally identifiable information, such as your name, shipping address, email address, and telephone number, that you voluntarily give to us when you register with the site or when you choose to participate in various activities related to the site.<br>
        - Derivative Data: Information our servers automatically collect when you access the site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the site.<br><br>

        2. **Use of Your Information**<br>
        Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the site to:<br>
        - Create and manage your account.<br>
        - Process your transactions and send you related information, including purchase confirmations and invoices.<br>
        - Send you marketing and promotional communications.<br>
        - Respond to customer service requests and support needs.<br><br>

        3. **Disclosure of Your Information**<br>
        We may share information we have collected about you in certain situations. Your information may be disclosed as follows:<br>
        - By Law or to Protect Rights: If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.<br><br>

        4. **Third-Party Websites**<br>
        The site may contain links to third-party websites and applications of interest, including advertisements and external services that are not affiliated with us. Once you have used these links to leave the site, any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information.<br><br>

        5. **Security of Your Information**<br>
        We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.<br><br>

        6. **Policy for Children**<br>
        We do not knowingly solicit information from or market to children under the age of 13. If we learn that we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible.<br><br>

        7. **Options Regarding Your Information**<br>
        You may at any time review or change the information in your account or terminate your account by:<br>
        - Logging into your account settings and updating your account.<br>
        - Contacting us using the contact information provided below.<br><br>

        8. **Contact Us**<br>
        If you have questions or comments about this Privacy Policy, please contact us at:<br>
        [Insert Contact Information]<br><br>

        This Privacy Policy is subject to change. We will notify you of any changes by updating the "Effective Date" at the top of this policy. We encourage you to review this Privacy Policy periodically to be informed of how we are protecting your information.
        </div>`,
        cancelText: 'Disagree',
        confirmText: 'Agree',
        type: 'is-success',
        class: 'custom-dialog',
        onConfirm: () => {
          form.policy_accepted = true;

        }
      })
    },
    policyAcceptance(token, email) { // Updated to accept optional email
      this.$buefy.dialog.confirm({
        title: "Findhub Customer Policy",
        message: `Findhub's Customer Acceptance Policy<br><br>
            At Findhub, we are committed to ensuring the safety and legality of our platform.This policy outlines our stance on searching for illegal and restricted products and the use of our website for inappropriate activities.< br > <br>
            1. **Prohibited Activities**<br>
              Users are strictly prohibited from using our portal to search for, promote, or engage in any illegal activities, including but not limited to:<br>
              - The sale or distribution of illegal drugs or controlled substances.<br>
              - The purchase or sale of weapons or explosives.<br>
              - The trafficking of stolen goods or counterfeit products.<br><br>

            2. **Sexual and Other Abuses**<br>
              Findhub does not tolerate any form of abuse, including but not limited to:<br>
              - The use of our platform to solicit or engage in sexual activities or exploitation.<br>
              - The promotion of hate speech, harassment, or any form of discrimination.<br>
                - The sharing of explicit or pornographic content.<br><br>

                                3. **User Responsibility**<br>
                                  Users are responsible for their actions while using our platform. We encourage users to report any suspicious or illegal activities they encounter. Failure to comply with this policy may result in account suspension or termination.<br><br>

                                    4. **Compliance with Laws**<br>
                                      Findhub complies with all applicable laws and regulations. We reserve the right to cooperate with law enforcement agencies in investigating any illegal activities conducted through our platform.<br><br>

                                        5. **Changes to This Policy**<br>
                                          This policy is subject to change. We will notify users of any changes by updating the "Effective Date" at the top of this policy. We encourage users to review this policy periodically to stay informed about our practices.<br><br>

            If you have any questions or concerns regarding this policy, please contact us at[Insert Contact Information].`,
        cancelText: "Disagree",
        confirmText: "Agree",
        type: "is-success",
        onConfirm: async () => {
          const response = await this.authStore.acceptPolicy(
            email, // Use provided email or fallback to this.form.email
            token
          );
          console.log("response from NAV 274", response);
          if (response.status === 200) {

            this.$buefy.toast.open(response.data.message);
            this.$emit('close');
          }
        },
      });
    },
    closeModal() {
      // Close the modal using Buefy's ModalProgrammatic method
      this.$parent.close();
    },
    async handleSignUp() {
      this.loading = true;
      try {
        const response = await this.authStore.signUp(this.signUpForm);
        if (response) {
          console.log("response", response);
          this.$buefy.notification.open({
            message: `Successfully create your account up and Logged in`,
            duration: 5000,
            progressBar: true,
            type: 'is-primary',
            pauseOnHover: true
          })
          this.$emit('close');
          this.$router.push("/");
        } else {
          console.log("policy_accepted");

        }
      } catch (error) {
        this.errorMessage = "Sign up failed. Please try again.";
      } finally {
        this.loading = false;
      }
    },
    async handleSignUpRequest() {
      this.loading = true;
      try {
        const response = await this.authStore.signupRequest(this.signUpRequestForm);
        if (!response.policy_accepted) {
          console.log("response", response);
          console.log("policy_not_accepted");
        } else {
          console.log("policy_accepted");
          this.$buefy.notification.open({
            message: `Successfully updated your profile up and Logged in`,
            duration: 5000,
            progressBar: true,
            type: 'is-primary',
            pauseOnHover: true
          })
          this.$emit('close');
          this.$router.push("/");
        }
      } catch (error) {
        this.errorMessage = "Sign up failed. Please try again.";
      } finally {
        this.loading = false;
      }
    },
    async handleGoogleLogin() {
      try {
        const result = await hellojs('google').login({
          scope: 'email',
          redirect_uri: '/oauth-redirect',
        })
        console.log('Google login successful:', result)

        const authRes = result.authResponse
        console.log('Google access token:', authRes)

        try {
          const response = await this.authStore.attempOAuth2Login(
            authRes.access_token,
            'google'
          )
          console.log('OAuth2 login successful:', response)

          // Check if policy is accepted
          if (response.policy_accepted == false) {
            console.log('Policy not accepted:');
            this.policyAcceptance(authRes.access_token, response.email); // Call policyAcceptance if policy not accepted
          } else {
            this.$router.push("/");
            this.$emit('close');
          }
        } catch (error) {
          console.error('OAuth2 login failed:', error)
          errorMessage.value = 'OAuth2 login failed. Please try again.'
        }

        this.$router.push("/");
        this.$emit('close');
      } catch (error) {
        console.error('Google login failed:', error)
        errorMessage.value = 'Google login failed. Please try again.'
      }
    },
    async handleForgotPassword() {
      
      // Logic to send the reset password link to the email
      // Example: await this.authStore.sendResetLink(this.signInForm.email);
      try {
        const response = await this.authStore.requestResetPassword(this.passwordReset.email);
        if (response.message == "Password reset OTP sent successfully") {
          this.isAwaitingCode = true;
          this.passwordResetProcess = 'emailSent';
          this.passwordReset.emailSent = true;
        }
        
      } catch (error) {
        console.log("Error sending reset password request:", error);
        this.passwordReset.errorMessages.email = error.message; // Capture the error message
      }
    },
    async handleVerifyCode() {
      try {
        const response = await this.authStore.verifyResetOTP(this.passwordReset.email, this.passwordReset.otp);
        if (response.message == "OTP verified successfully") {
          this.passwordResetProcess = 'otpVerified';
        }
      } catch (error) {
        // Handle API error response

        this.passwordReset.errorMessages.otp = error.message;
        // Clear the OTP input
        this.clearOtpInput();
      }
    },
    async changePassword() {
      const response = await this.authStore.changePassword(this.passwordReset.email, this.passwordReset.password,);
      if (response.message == "Password has been successfully reset") {
        this.passwordResetProcess = 'changed'
        this.isAwaitingCode = false;
      }
      console.log(response.message)

    },
  },

  mounted() {
    hellojs.init({
      google: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      redirect_uri: "/oauth-redirect",
    });
  },
};
</script>
<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

.otp-input.is-complete {
  background-color: #e4e4e4;
}

.otp-input input[disabled] {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.navbar-item img {
  max-height: 3.75rem;
}

.fh_login_modal-card {
  height: auto;
  width: auto;
}

.fh_otp_login {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog .modal-card {
  max-width: 600px;
  width: auto;
}

.custom-dialog .modal-card {
  max-width: 800px !important;

}
</style>

<template>
  <div class="card fh_seller_directory_details_modal-box">
    <div class="fh_seller_directory_details_modal-box-coverimg">
      <img src="/img/seller_banner_bg.jpeg" alt="seller cover image" />
    </div>
    <p class="image is-64x64 fh_seller_directory_details_modal-box-logo">
      <template v-if="seller.logo">
        <img :src="imageUrl(seller.logo)" />
      </template>
      <template v-else>
        <div class="avatar-initials">
          {{
            seller.business_name
              .split(" ")
              .slice(0, 2)
              .map((name) => name.charAt(0))
              .concat(
                seller.business_name.split(" ").length > 2
                  ? seller.business_name.split(" ")[2].charAt(0)
                  : ""
              )
              .slice(0, 2)
              .join("")
              .toUpperCase()
          }}
        </div>
      </template>
    </p>
    <div class="card-content">
      <div class="content">
        <h4>{{ seller.business_name }}</h4>
        <p>
          {{ formatAddress(seller.address_line_1) }} ,
          {{ formatAddress(seller.address_line_2) }}
        </p>
        <p>{{ seller.core_business }}</p>
        <p>{{ seller.city_name }}</p>
        <p>{{ seller.country_name }}</p>
        <b-taglist
          v-if="Array.isArray(seller.keywords) && seller.keywords.length"
        >
          <b-tag v-for="keyword in seller.keywords" :key="keyword.name">
            #{{ keyword.name }}
          </b-tag>
        </b-taglist>
        <br />
        Working Hours : {{ formatTime(seller.starting_time) }} -
        {{ formatTime(seller.closing_time) }}
      </div>
    </div>
    <footer class="card-footer">
      <a :href="'tel:' + seller.phone" class="card-footer-item">
        <i class="mdi mdi-phone"></i>
      </a>
      <a :href="'mailto:' + seller.email" class="card-footer-item">
        <i class="mdi mdi-email"></i>
      </a>
      <a
        :href="
          'https://www.google.com/maps/dir/?api=1&destination=' +
          seller.latitude +
          ',' +
          seller.longitude
        "
        class="card-footer-item"
      >
        <i class="mdi mdi-map-marker"></i>
      </a>
    </footer>
  </div>
</template>

<script>
/* eslint-disable */ 
export default {
  props: {
    seller: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isActive: true,
    };
  },
  methods: {
    closeModal() {
      this.isActive = false;
      this.$emit("close"); // Emit close event to parent
    },
    formatAddress(address) {
      return address
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
  },
};
</script>

<style scoped>
.modal-content {
  padding: 1rem;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}
.fh_seller_directory_details_modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35rem;
}

.avatar-initials {
  background-color: #bf7e04;
  /* Change to your desired color */
  color: #ffffff;
  /* Text color */
  font-size: 1.8rem;
  /* Large font size */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  /* Match the image size */
  height: 64px;
  /* Match the image size */
  border-radius: 50%;
  /* Make it circular */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.fh_seller_directory_details_modal-box-logo {
  display: flex;
  align-items: center;
  position: absolute;
  top: 1rem;
  right: 2rem;
}
</style>

// import { useAuthStore } from "./auth";
// import { useLocationStore } from "./location";
// import { useSearchStore } from "./search";

// export { useAuthStore, useLocationStore, useSearchStore };

// // You can also add a default export if you prefer
// export default {
//   useAuthStore,
//   useLocationStore,
//   useSearchStore,
// };
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

// Create Pinia instance
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export default pinia; // Export the configured Pinia instance

// Re-export the individual stores
export { useAuthStore } from "./auth";
export { useLocationStore } from "./location";
export { useSearchStore } from "./search";
export { useRefCodeStore } from "./refCodeStore";
export { useCityselectStore } from "./cityselectStore";
export { useCartStore } from "./cart";
export { useOrderStore } from "./order";
export { usePageStore } from "./page";
export { useModalStore } from "./modalStore";
export { useMessageStore } from "./messageStore";

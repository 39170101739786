<template>
  <div class="fh_title_box_shape">Recent Searches</div>
  <div class="fh_main_recent_search-container">
    <router-link
      v-for="search in searchStore.recentSearches.slice(
        0,
        this.visibleSearchCount
      )"
      :key="search.id"
      :to="`/search/${search.id}`"
      class="column fh_main_recent_search-item"
    >
      <article class="media">
        <figure class="media-left">
          <p class="image is-32x32">
            <img
              :src="
                search.status === 'responded'
                  ? '/icons/double-check.gif'
                  : '/icons/work.gif'
              "
            />
          </p>
        </figure>
        <div class="media-content">
          <div class="content is-small">
            <h6 class="is-6 mb-1">
              {{
                search.term.length > this.maxLength
                  ? search.term.substring(0, this.maxLength) + "..."
                  : search.term
              }}
            </h6>
            <p class="mb-1">{{ formatDate(search.search_on) }}</p>
            <b-tag
              :type="
                search.status === 'responded'
                  ? 'is-primary'
                  : 'is-primary is-light'
              "
              >{{
                search.status === "responded" ? "Found Result" : "No Results"
              }}</b-tag
            >
          </div>
        </div>
      </article>
    </router-link>
  </div>
</template>

<script>
/* eslint-disable */ 
import { useSearchStore, useAuthStore } from "@/store"; // Adjust the path as necessary

export default {
  data() {
    return { 
      recentSearches: [] // Initialize recentSearches as an empty array
    }; 
  },
  computed: {
    maxLength() {
      return window.innerWidth <= 600 ? 15 : 35; // Set max length based on screen width
    },
    visibleSearchCount() {
      if (window.innerWidth < 600) {
        return 2; // Less than 600
      } else if (window.innerWidth <= 1024) {
        return 3; // Between 600 and 1024
      } else {
        return 5; // More than 1024
      } // Set visible count based on screen width
    }
  },
  created() {
    const searchStore = useSearchStore(); // Move searchStore initialization here
    const authStore = useAuthStore(); // Initialize authStore if needed
    
    this.searchStore = searchStore;
    this.authStore = authStore // Assign searchStore to this for access in mounted
  },

  async mounted() {
    // Check if the user is authenticated and if there are recent searches
    if (this.authStore.isAuthenticated) {
      this.recentSearches = await this.searchStore.viewAllSearchTerm(); // Access searchStore from this
      if (this.recentSearches.length > 0) {
        // console.log(`Recent Search List`, this.recentSearches); //
      } else {
        // console.log("No recent searches found.");
      }
    } else {
      // console.log("User is not authenticated.");
    }
  },
};
</script>

<style scoped>
/* Add your styles here */
.fh_main_recent_search-item{
  background-color: white !important;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  max-width: 20rem;
  padding: .75rem;
}

.fh_main_recent_search-container {
display: flex;
gap: 1rem;
}

@media (max-width: 600px) {
  .fh_main_recent_search-box {

  }
  .fh_main_recent_search-item {

    max-width: 16rem;

  }
}
</style>

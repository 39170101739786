import { defineStore } from "pinia";
import { refCodeService } from "@/services/refCodeService";

export const useRefCodeStore = defineStore("refCode", {
  state: () => ({
    referenceCodes: {},
    isLoading: false,
    error: null,
  }),

  actions: {
    async refreshReferenceCodes(classifiers) {
      this.isLoading = true;
      this.error = null;
      try {
        const response = await refCodeService.getReferenceCodes(classifiers);
        this.updateReferenceCodes(response.data);
      } catch (error) {
        console.error("Error loading reference codes:", error);
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },

    updateReferenceCodes(data) {
      Object.entries(data).forEach(([classifier, classifierData]) => {
        this.referenceCodes[classifier] = {
          data: classifierData,
          last_refresh_time: Date.now(),
        };
      });
    },
  },
});

import axios from "axios";
import Cookies from "universal-cookie"; // for handling cookies
const cookies = new Cookies();
// const TOKEN_KEY = "authToken"; // Key for storing the token in localStorage
const apiUrl = process.env.VUE_APP_API_URL;
export class FileuploadService {
  static async uploadFile(file, path, is_public) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("path", path);
    formData.append("type", is_public);

    const response = await axios.post(
      `/api/seller/file/file-upload-s3`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-xsrf-token": cookies.get("XSRF-TOKEN"), // Pass the CSRF token
        },
        withCredentials: true, // Enable withCredentials
      }
    );
    return response.data;
  }

  static getFileUrl(file_id) {
    return `${apiUrl}/api/seller/file/` + file_id;
  }
  static getPageImageUrl(file_id) {
    return `${apiUrl}/storage/${file_id}`; // Relative URL
  }
}

import { defineStore } from "pinia";
import orderService from "@/services/order.service";

export const useOrderStore = defineStore("order", {
  state: () => ({
    orders: [],
    allOrders: [],
  }),
  actions: {
    async placeOrder(orderData) {
      const newOrder = await orderService.placeOrder(orderData);
      this.orders.push(newOrder);
      return newOrder;
    },
    async updateShippingAddress(addressData) {
      const newOrder = await orderService.updateShippingAddress(addressData);
      console.log(newOrder);
      this.orders.push(newOrder);
    },
    async updatePaymentMode(paymentMode) {
      const newOrder = await orderService.updatePaymentMode(paymentMode);
      return newOrder;
    },
    async confirmPayment(paymentMode) {
      return await orderService.confirmPayment(paymentMode);
    },
    async getOrders() {
      this.orders = await orderService.getOrders();
    },
    async getAllMyOrders() {
      const myOrders = await orderService.getAllMyOrders();
      this.allOrders = myOrders;
      return myOrders;
    },
  },
});

import { defineStore } from "pinia";
import { MapUtil } from "../utils/map.util";
import { requestFCMToken } from "../services/firebaseService";
import authService from "../services/auth.service"; // Import the auth service
import { useAuthStore } from "./auth"; // Import the auth store

export const useLocationStore = defineStore("locationStore", {
  state: () => ({
    currentLocation: null,
    locationError: null,
    locationRequested: false,
    fcmToken: null,
    selectedLocation: { lat: null, lng: null }, // New state for selected location
    userLocations: [],
  }),
  actions: {
    updateSelectedLocation(displayName, lat, lng) {
      this.currentLocation = { displayName, lat, lng };
    },
    setLocationError(error) {
      this.locationError = error;
    },
    setSelectedLocation(lat, lng) {
      // console.log("Setting in Store SelectedLocation", lat, lng);
      // console.log("Before setting selectedLocation:", this.selectedLocation);
      this.selectedLocation = { lat, lng };
      // console.log("After setting selectedLocation:", this.selectedLocation);
    },
    setUserLocations(locationArray) {
      console.log("Setting user Location after loging in ", locationArray);
      // console.log("Before setting selectedLocation:", this.selectedLocation);
      this.userLocations = locationArray;
      // console.log("After setting selectedLocation:", this.selectedLocation);
    },
    checkingExistingLocationData() {
      const existingData =
        JSON.parse(localStorage.getItem("findhub_spa")) || {};
      if (existingData.lat && existingData.lng) {
        // If lat and lng are available, update the location without fetching from Google API
        this.updateSelectedLocation(
          existingData.displayName || "Saved Location", // Use a default display name if not available
          existingData.lat,
          existingData.lng
        );
        return true; // Indicate that location was updated
      }
      return false; // Indicate that no location data was found
    },
    async fetchCurrentLocation() {
      if (!("geolocation" in navigator)) {
        this.setLocationError("Geolocation is not supported by this browser.");
        return;
      }
      console.log("Fetching current location Called");
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          });
        });
        //eslint-disable-next-line no-undef
        const { latitude, longitude } = position.coords;
        //eslint-disable-next-line no-undef
        const geocoder = new google.maps.Geocoder();

        const response = await geocoder.geocode({
          location: { lat: latitude, lng: longitude },
          language: "en", // Specify English as the language
        });

        if (response.results[0]) {
          const placeInfo = response.results[0];
          const placeData = MapUtil.parseAddressComponents(
            placeInfo.address_components
          );
          const placeName = MapUtil.getLocalDisplayName(placeData); // Get the place name
          // this.updateSelectedLocation(
          //   placeName,
          //   placeInfo.geometry.location.lat(),
          //   placeInfo.geometry.location.lng()
          // );
          this.setSelectedLocation(
            placeInfo.geometry.location.lat(),
            placeInfo.geometry.location.lng()
          );
          this.setCurrentLocation(
            placeInfo.geometry.location.lat(),
            placeInfo.geometry.location.lng(),
            placeName
          );
        } else {
          this.setLocationError("No results found");
        }
      } catch (error) {
        let errorMessage;
        switch (error.code) {
          case error.PERMISSION_DENIED:
            errorMessage =
              "Location permission denied. Please enable location access in your browser settings.";
            break;
          case error.POSITION_UNAVAILABLE:
            errorMessage = "Location information is unavailable.";
            break;
          case error.TIMEOUT:
            errorMessage = "The request to get user location timed out.";
            break;
          default:
            errorMessage =
              "An unknown error occurred while trying to get location.";
        }
        this.setLocationError(errorMessage);
        console.error("Geolocation error:", error);
      } finally {
        this.locationRequested = true;
      }
    },
    checkAndRequestLocation() {
      if (!this.checkingExistingLocationData()) {
        // Check if existing location data is available
        this.fetchCurrentLocation(); // Fetch current location if no existing data
      }
    },
    async registerFCMToken() {
      const authStore = useAuthStore(); // Access the auth store
      const userData = localStorage.getItem("findhub_spa"); // Updated to fetch userData from findhub_spa
      if (!authStore.isAuthenticated || !userData) {
        console.log(
          "User is not authenticated, setting current location to default"
        );
        return; // Exit if conditions are not met
      }
      const token = await requestFCMToken();
      if (token) {
        // console.log("FCM Token:", token); // Log the FCM token for debugging
        this.fcmToken = token; // Store the FCM token in the state
        await authService.saveFcmToken(token); // Save the FCM token to the backend
      }
    },
    setCurrentLocation(lat, lng, displayName) {
      // Retrieve existing data from localStorage
      const existingData =
        JSON.parse(localStorage.getItem("findhub_spa")) || {};
      // Merge existing data with new location data
      const updatedData = {
        ...existingData,
        lat,
        lng,
        displayName,
      };

      // Store the updated data back in localStorage
      localStorage.setItem("findhub_spa", JSON.stringify(updatedData));

      // Update the store state
      this.updateSelectedLocation(displayName, lat, lng);
    },
    async setLocationSelector(address) {
      // Initialized location selector
      this.userLocations = [];

      if (this.currentLocation) {
        // Check if currentLocation is available
        console.log("Checking if currentLocation is available");
        this.userLocations.push({
          location: this.currentLocation,
          type: "Current Location",
        });
      }

      // Push Nearest Location to Array
      let nearbyAddress = null;
      let nearByAddresses = null;
      if (
        this.currentLocation &&
        this.currentLocation.lat &&
        this.currentLocation.lng
      ) {
        console.log("Checking if Nearest Location is available");
        nearByAddresses = await authService.getUserNearbyAddress(
          this.currentLocation.lat,
          this.currentLocation.lng
        );
      }

      if (nearByAddresses && nearByAddresses.length > 0) {
        nearbyAddress = nearByAddresses[0];
        this.userLocations.push({
          location: nearbyAddress,
          type: "Near Location",
        });
      }

      console.log("Checking default Address");
      if (address && address.length > 0) {
        const defaultAddress = address.find((address) => address.is_default);
        if (defaultAddress) {
          // Check if IDs are different
          if (!nearbyAddress || defaultAddress.id !== nearbyAddress.id) {
            this.userLocations.push({
              location: defaultAddress,
              type: "Default Address",
            });
          }
        }
      }

      if (this.userLocations.length == 0) {
        // If no locations found, add a default location
        this.userLocations.push({
          location: { lat: 25.3470613, lng: 55.3887623 },
          type: "Error Unable To Find Location",
        });
        // console.log("Added default location to locationSelect");
      }

      //
    },
  },
  getters: {
    getCurrentLocation(state) {
      return state.currentLocation || { lat: 25.3470613, lng: 55.3887623 }; // Default values
    },
    getSelectedLocation(state) {
      return state.selectedLocation || { lat: 25.3470613, lng: 55.3887623 }; // Default values
    },
  },
  mutations: {
    SET_FCM_TOKEN(state, token) {
      state.fcmToken = token;
    },
  },
});
